import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteDropdownService {
  private autoCompleteActive: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() { }

  public activeAutoComplete():void {
    this.autoCompleteActive.next(false);
  }

  public getAutoComplete$() {
    return this.autoCompleteActive.asObservable();
  }

  getAutoComplete():boolean {
    return this.autoCompleteActive.value;
  }
}
