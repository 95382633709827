<div class="homeContactButtonMark" id="sectionRelease">
  <button id="buttonContato" class="btn btn-contact-chat btn-rounded btn-contact-me-NEW d-none d-sm-none d-md-block"
          *ngIf="!showFab"
          type="button" (click)="showHide()"><span class="chat-icon"></span> <span><span
    style="color:#575656;">Entre em</span><br> CONTATO</span>
  </button>
  <a class="btn btn-contact-chat btn-circle btn-xl btn-contact-me-NEW d-block d-sm-block d-md-none" *ngIf="!showFab"
     data-toggle="modal" data-target="#contactMe" (click)="showHide()"><span class="chat-icon"></span></a>
  <div class="" id="groupButtomContact" style="" *ngIf="showFab">
    <a class="btn btn-secondary-action btn-sca-phone btn-rounded text-uppercase" (click)="showHide('tel:01925120000')">
      <img class="mr-1" alt="" title="" width="22" src="assets/img/icons/icon-phone.svg"/> <span><span
      style="color:#575656;">LIGUE AGORA</span><br>2512-0000</span>
    </a>
    <a target="_blank" rel=”noopener”
       class="btn btn-secondary-action btn-sca-whatsapp btn-rounded text-uppercase"
       (click)="showHide('https://api.whatsapp.com/send?phone=5519999120030&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20seus%20im%C3%B3veis!%20')">
      <img class="mr-1" alt="" title="" width="22" src="assets/img/icons/icon-whatsapp.svg"/> <span><span
      style="color:#575656;">whatsapp</span><br>(19) 99912-0030</span>
    </a>
    <button class="btn btn-secondary-action btn-sca-contact btn-rounded text-uppercase"
            (click)="open(content)">
      <img class="mr-1" width="22" alt="" title="" src="assets/img/icons/icon-contact-pq.svg"> MENSAGEM
    </button>
    <button type="button" id="btnContactTimes" class="btn bnt-sca-times" (click)="showHide()"><img width="22" alt=""
                                                                                                   title=""
                                                                                                   src="assets/img/icons/icon-times-contact.svg">
    </button>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-content">
    <form [formGroup]="form"
          id="form-negotiate-property">

      <div class="card text-center">
        <div class="card-header text-dark-gray bg-gray-100 font-weight-bolder text-uppercase py-3">
          Entre em contato
          <button type="button" class="close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="card-body text-left bg-gray-100 p-2 pt-5">
            <div class="row">
              <div class="offset-md-1 offset-lg-1 col-12 col-md-10 col-lg-10">
                <div class="form-group">
                  <div class="form-icon">
                    <span id="validate_contact_name"></span>
                    <input type="text" class="form-control orange-input" name="form-contact-name"
                           id="form-contact-name"
                           formControlName="nome" placeholder="Nome completo" autocomplete="off" required="required">
                    <span id="alert_contact_name" class="float-left blur-error"></span>
                  </div>
                </div>
              </div>

              <div class="offset-md-1 offset-lg-1 col-12 col-md-10 col-lg-10">
                <div class="form-group">
                  <div class="form-icon">
                    <span id="validate_contact_phone"></span>
                    <input type="text" class="form-control phone orange-input" name="form-contact-phone"
                           id="form-contact-phone" placeholder="Celular" autocomplete="off" required="required"
                           formControlName="telefone" [textMask]="{mask: MASKS.celular.textMask}">
                  </div>
                  <span id="alert_contact_phone" class="float-left blur-error"></span>
                </div>
              </div>

              <div class="offset-md-1 offset-lg-1 col-12 col-md-10 col-lg-10">
                <div class="form-group">
                  <div class="form-icon">
                    <span id="validate_contact_email"></span>
                    <input type="email" class="form-control orange-input" name="form-contact-email"
                           id="form-contact-email" value="" placeholder="Digite seu e-mail aqui" autocomplete="off"
                           formControlName="email"
                    >
                  </div>
                  <span id="alert_contact_email" class="float-left blur-error"></span>
                </div>
              </div>

              <div class="offset-md-1 offset-lg-1 col-12 col-md-10 col-lg-10">
                <div class="form-group ">
                  <label for="form-contact-message">Mensagem</label>
                  <span id="alert_message" class="float-right blur-error"></span>
                  <textarea class="form-control orange-textarea" name="form-contact-message" id="form-contact-message"
                            rows="3"
                            placeholder="Mensagem (Opcional)" formControlName="mensagem"></textarea>
                </div>
              </div>
              <div class="col-12 text-center my-4">
                <button class="btn btn-sing-up btn-rounded btn-primary text-uppercase mt-3 btn-negotiate-modal"
                        id="btn-negotiate-modal" [disabled]="!form.valid"
                        (click)="submitForm()">
                  Enviar
                  <img class="btn-invisible" src="../../assets/img/spinner.gif" id="negotiate_modal_spinner"
                       width="23"/>
                </button>
              </div>
            </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
