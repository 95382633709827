



  <div id="backdrop">
    <button id="buttonContato" class="btn btn-contact-chat btn-rounded btn-contact-me-NEW d-none d-sm-none d-md-block"
            type="button"><span class="chat-icon"></span> <span><span style="color:#575656;">Entre em</span><br> CONTATO</span>
    </button>

    <a class="btn btn-contact-chat btn-circle btn-xl btn-contact-me-NEW d-block d-sm-block d-md-none"
       data-toggle="modal" data-target="#contactMe"><span class="chat-icon"></span></a>

    <div class="d-none" id="groupButtomContact" style="">

      <a href="noticia1.html#" class="btn btn-secondary-action btn-sca-phone btn-rounded text-uppercase">
        <img class="mr-1" alt="" title="" width="22" src="../assets/img/icons/icon-phone.svg"/> <span><span
        style="color:#575656;">LIGUE AGORA</span><br>2512-0000</span>
      </a>

      <a target="_blank" rel=”noopener”
         href="https://api.whatsapp.com/send?phone=5519999120030&text=Ol%C3%A1%2C%20quero%20saber%20mais%20sobre%20seus%20im%C3%B3veis!%20"
         class="btn btn-secondary-action btn-sca-whatsapp btn-rounded text-uppercase">
        <img class="mr-1" alt="" title="" width="22" src="../assets/img/icons/icon-whatsapp.svg"/> <span><span
        style="color:#575656;">whatsapp</span><br>(19) 99912-0030</span>
      </a>

      <a href="noticia1.html#" class="btn btn-secondary-action btn-sca-contact btn-rounded text-uppercase"
         data-toggle="modal" data-target="#contactMe">
        <img class="mr-1" width="22" alt="" title="" src="../assets/img/icons/icon-contact-pq.svg"> MENSAGEM
      </a>

      <button type="button" id="btnContactTimes" class="btn bnt-sca-times"><img width="22" alt="" title=""
                                                                                src="../assets/img/icons/icon-times-contact.svg">
      </button>

    </div>

    <section class="container-fluid">

      <div class="row blog-single-banner"
           [ngStyle]="{'background-image': 'url(' + post?.image + ')'}"
           style="background-size: cover; background-position: top center;"></div>

      <div class="blog-single-content container">

        <div class="row">
          <div class="col-12 col-lg-2">
            <p class="blog-single-date">
              Publicado em:<br>
              <b>{{date(post?.date)}}</b>
            </p>
          </div>

          <div class="col-12 col-lg-10">
            <label class="blog-single-label"></label>
            <h1>{{post?.title?.rendered}}</h1>
            <span class="line"></span>
            <h2></h2>

            <div class="blog-text" [innerHTML]="post?.content?.rendered">
            </div>
            <div class="blog-footer" style="display: none;" ngif>
              <div class="row">
                <div class="col-12 col-lg-6 text-left blog-footer-sm-center">
                  <a href="javascript:history.back();"
                     class="btn blog-footer-btn btn-semi-rounded bright_orange btn-wrapper-blog"><i
                    class=" bright_orange btn-back-blog"></i> Voltar</a>
                </div>
                <div class="col-12 col-lg-6 text-right blog-footer-sm-center">
                  <a class="btn blog-footer-btn btn-semi-rounded bright_orange mt-3 mt-md-0" data-toggle="collapse"
                     data-target="#share">
                    <img src="../assets/img/Outline_1_.svg" style="width: 28px; margin-right: 23px;">Compartilhar
                  </a>
                </div>
                <div id="share" class="collapse col-md-12 mx-auto">
                  <div class="mt-3 text-right">
                    <div class="addthis_inline_share_toolbox"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="blog-sugestoes d-none d-md-block" style="display: none;" *ngIf="false">
      <div class="container ">
        <div class="row ">
          <div class="col-12">
            <div class="offset-1 col-md-10">
              <h4><img src="../assets/img/icons/icon-plus.svg"> Sugestões para você</h4>
            </div>
          </div>



          <div class="col-8 col-lg-10 ">

            <div class="row BlogCard BlogCardShadowBox animated fadeIn all_slides_hook" style="" id="slide_0"
                 data-slide="0">

              <div class="col-lg-5 px-0 " style="overflow: hidden;">
                <a href="noticia4.html" title="">
                  <div class="BlogCardCover"
                       style="background-image: url('../storage/news/design-de-interiores-10-lindos-apartamentos-thumb.jpeg');">
                  </div>
                </a>
              </div>

              <div class="col-lg-7 blog-padding-left-50">


                <div class="row margin-top-30">
                  <div class="col-12">
                    <h6 class="text-orange text-uppercase font-weight-bold" style="letter-spacing: 2px;">Design de
                      Interior</h6>
                    <h2 class="text-dark-gray blog-title pt-2 pb-3 ">Noticia 4</h2>
                    <p class="text-gray-300">Sub Noticia 4</p>
                    <a href="noticia4.html" class="btn btn-see-more btn-rounded" title="">
                      VER MAIS <span class="arrow-icon"></span>
                    </a>
                  </div>

                </div>

              </div>
            </div>
            <div class="row BlogCard BlogCardShadowBox animated fadeIn all_slides_hook" style="display:none"
                 id="slide_1" data-slide="1">

              <div class="col-lg-5 px-0 " style="overflow: hidden;">
                <a href="noticia3.html" title="">
                  <div class="BlogCardCover"
                       style="background-image: url('../storage/news/lorem-ipsum-dolor-sit-amet-thumb.jpeg');">
                  </div>
                </a>
              </div>

              <div class="col-lg-7 blog-padding-left-50">


                <div class="row margin-top-30">
                  <div class="col-12">
                    <h6 class="text-orange text-uppercase font-weight-bold" style="letter-spacing: 2px;">Lançamento</h6>
                    <h2 class="text-dark-gray blog-title pt-2 pb-3 ">Noticia 3</h2>
                    <p class="text-gray-300">Sub Noticia 3</p>
                    <a href="noticia3.html" class="btn btn-see-more btn-rounded" title="">
                      VER MAIS <span class="arrow-icon"></span>
                    </a>
                  </div>

                </div>

              </div>
            </div>
            <div class="row BlogCard BlogCardShadowBox animated fadeIn all_slides_hook" style="display:none"
                 id="slide_2" data-slide="2">

              <div class="col-lg-5 px-0 " style="overflow: hidden;">
                <a href="noticia2.html" title="">
                  <div class="BlogCardCover" style="background-image: url('../storage/news/noticia2-thumb.jpeg');">
                  </div>
                </a>
              </div>

              <div class="col-lg-7 blog-padding-left-50">


                <div class="row margin-top-30">
                  <div class="col-12">
                    <h6 class="text-orange text-uppercase font-weight-bold" style="letter-spacing: 2px;">Lançamento</h6>
                    <h2 class="text-dark-gray blog-title pt-2 pb-3 ">Noticia 2</h2>
                    <p class="text-gray-300">Sub Noticia 3</p>
                    <a href="noticia2.html" class="btn btn-see-more btn-rounded" title="">
                      VER MAIS <span class="arrow-icon"></span>
                    </a>
                  </div>

                </div>

              </div>
            </div>
            <div class="row BlogCard BlogCardShadowBox animated fadeIn all_slides_hook" style="display:none"
                 id="slide_3" data-slide="3">

              <div class="col-lg-5 px-0 " style="overflow: hidden;">
                <a href="noticia5.html" title="">
                  <div class="BlogCardCover" style="background-image: url('../storage/news/noticia5-thumb.jpeg');">
                  </div>
                </a>
              </div>

              <div class="col-lg-7 blog-padding-left-50">


                <div class="row margin-top-30">
                  <div class="col-12">
                    <h6 class="text-orange text-uppercase font-weight-bold" style="letter-spacing: 2px;">Decoração</h6>
                    <h2 class="text-dark-gray blog-title pt-2 pb-3 ">Noticia 5</h2>
                    <p class="text-gray-300">Sub Noticia 5</p>
                    <a href="noticia5.html" class="btn btn-see-more btn-rounded" title="">
                      VER MAIS <span class="arrow-icon"></span>
                    </a>
                  </div>

                </div>

              </div>
            </div>
            <div class="row BlogCard BlogCardShadowBox animated fadeIn all_slides_hook" style="display:none"
                 id="slide_4" data-slide="4">

              <div class="col-lg-5 px-0 " style="overflow: hidden;">
                <a href="noticia6.html" title="">
                  <div class="BlogCardCover" style="background-image: url('../storage/news/noticia6-thumb.jpeg');">
                  </div>
                </a>
              </div>

              <div class="col-lg-7 blog-padding-left-50">


                <div class="row margin-top-30">
                  <div class="col-12">
                    <h6 class="text-orange text-uppercase font-weight-bold" style="letter-spacing: 2px;">Decoração</h6>
                    <h2 class="text-dark-gray blog-title pt-2 pb-3 ">Noticia 6</h2>
                    <p class="text-gray-300">Sub Noticia 6</p>
                    <a href="noticia6.html" class="btn btn-see-more btn-rounded" title="">
                      VER MAIS <span class="arrow-icon"></span>
                    </a>
                  </div>

                </div>

              </div>
            </div>
            <ul class="blog-slider-pagination text-center">

              <li class="active balls" id="ball_0" data-ball="0" onclick="ClickBall(0)"></li>

              <li class=" balls" id="ball_1" data-ball="1" onclick="ClickBall(1)"></li>

              <li class=" balls" id="ball_2" data-ball="2" onclick="ClickBall(2)"></li>

              <li class=" balls" id="ball_3" data-ball="3" onclick="ClickBall(3)"></li>

              <li class=" balls" id="ball_4" data-ball="4" onclick="ClickBall(4)"></li>
            </ul>
          </div>
          <div class="col-2 col-lg-1 text-right">
            <a onclick="NextSlideBlog()" class="arrow-right-blog">
              <img src="../assets/img/propertyRightArrow.png" alt="">
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
