<div id="backdrop">

  <div class="container-fluid Services">
    <div class="row">
      <div class="col-10 offset-1  mt-5 mb-5">
        <a class="btn btn-see-more btn-rounded" href="javascript:history.back();"><i class="PropertyArrowBackBtn"></i>
          VOLTAR</a>
      </div>

      <div class="col-10 offset-1 text-center mt-0 mt-sm-5">
        <h1>SERVIÇOS E FORMULÁRIOS</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 mt-5 mb-5">
        <div class="accordion" id="accordionExample">

          <div class="card">
            <div class="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree"
                 [class.show]="showForm3" (click)="showForm3 = !showForm3"
                 aria-expanded="false" aria-controls="collapseThree">
              <div class="row py-3">
                <div class="col-10"><h2 [class.orange-text]="showForm3">CADASTRO DE PESSOA FÍSICA</h2></div>
                <div class="col-2 text-right">
                  <i class="fas pull-right fa-plus-circle" *ngIf="!showForm3"></i>
                  <i class="fas pull-right orange-text fa-minus-circle" *ngIf="showForm3"></i>
                </div>
              </div>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample"
                 [class.show]="showForm3">
              <div class="card-body">
                <div class="form-card">
                  <form class="col-12 col-lg-10 offset-lg-1 js-form-to-email" [formGroup]="form1"
                        id="FormSevicos">
                    <p>Preencha e envie o formulário abaixo para se cadastrar.</p>
                    <fieldset class="form-fieldset">
                      <div class="row">
                        <div class="col-12 col-lg-12 form-group">
                          <label for="sou-um">Sou um:</label>
                          <div class="form-check form-check-inline main-checkbox" style="margin-left: 5px">
                            <input class="form-check-input" type="radio" id="locatario" value="Locatário"
                                   [formControlName]="'radio1'">
                            <span></span>
                            <label class="form-check-label" for="locatario">Locatário</label>
                          </div>
                          <div class="form-check form-check-inline main-checkbox">
                            <input class="form-check-input" type="radio" id="fiador" value="Fiador"
                                   [formControlName]="'radio1'">
                            <span></span>
                            <label class="form-check-label" for="fiador">Fiador</label>
                          </div>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="matricula">Matrícula do imóvel</label>
                          <input type="text" class="form-control" placeholder="Número da matrícula" id="matricula"
                                 name="matricula" [formControlName]="'field1'">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="nome">Nome</label>
                          <input type="text" class="form-control" placeholder="Nome Completo" id="nome" name="nome"
                                 [formControlName]="'field2'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="email">E-mail</label>
                          <input type="email" class="form-control" placeholder="Digite seu e-mail aqui" name="email"
                                 [formControlName]="'field3'"
                                 id="email" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="telefone">Telefone/celular</label>
                          <input type="text" class="form-control js-mask-phone" name="telefone" id="telefone"
                                 [formControlName]="'field4'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 placeholder="( ) 00000-0000" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="dataDeNascimento">Data de nascimento</label>
                          <input type="text" class="form-control js-mask-date" name="data-de-nascimento"
                                 [formControlName]="'field5'" [textMask]="{mask: MASKS.data.textMask}"
                                 id="dataDeNascimento" placeholder="Dia / Mês / Ano" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="nacionalidade">Nacionalidade</label>
                          <input type="text" class="form-control" name="nacionalidade" id="nacionalidade"
                                 [formControlName]="'field6'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="rg">RG</label>
                          <input type="text" class="form-control" name="rg" id="rg" placeholder="00000000-0" required
                                 [formControlName]="'field7'">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cpf">CPF</label>
                          <input type="text" class="form-control js-mask-cpf" name="cpf" id="cpf"
                                 [textMask]="{mask: MASKS.cpf.textMask}"
                                 [formControlName]="'field8'"
                                 placeholder="000.000.000-00" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="estadoCivil">Estado civíl</label>
                          <select class="form-control" name="estado-civil" id="estadoCivil" required
                                  [formControlName]="'field9'">
                            <span></span>
                            <option value=""></option>
                            <option value="Casado">Casado</option>
                            <option value="Solteiro">Solteiro</option>
                            <option value="Divorciado">Divorciado</option>
                            <option value="Viúvo">Viúvo</option>
                          </select>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="profissao">Profissão</label>
                          <input type="text" class="form-control" name="profissao" id="profissao" placeholder=" "
                                 [formControlName]="'field10'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="localDeTrabalho">Local de trabalho</label>
                          <input type="text" class="form-control" name="local-de-Trabalho" id="localDeTrabalho"
                                 [formControlName]="'field11'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="funcao">Função</label>
                          <input type="text" class="form-control" name="funcao" id="funcao" placeholder=" " required
                                 [formControlName]="'field12'">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="salario">Salário</label>
                          <input type="text" class="form-control js-mask-money" name="salario" id="salario"
                                 [formControlName]="'field13'" [textMask]="{mask: MASKS.currency.textMask}"
                                 placeholder=" " required>
                          <span></span>
                        </div>
<!--                        <div class="col-12 col-lg-6 form-group">-->
<!--                          <label for="cartorio">Cartório para reconhecimento de firma</label>-->
<!--                          <input type="text" class="form-control" name="cartorio" id="cartorio" placeholder=" "-->
<!--                                 [formControlName]="'field15'"-->
<!--                                 required>-->
<!--                          <span></span>-->
<!--                        </div>-->
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-corretor">Corretor</label>
                          <select class="form-control field-corretor clickable" name="estado-civil" id="rand2-corretor"
                                  required
                                  [formControlName]="'field26'">
                            <span></span>
                            <option value=""></option>
                            <option value="{{corretor.email_do_corretor}}"
                                    *ngFor="let corretor of corretores">{{corretor.nome_do_corretor}}</option>
                          </select>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-3 col-12">DADOS DO CONJUGUE</h6>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="nome">Nome</label>
                          <input type="text" class="form-control" placeholder="Nome Completo" id="nome"
                                 [formControlName]="'field17'"
                                 name="conjuge-nome" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="telefone">Telefone/celular</label>
                          <input type="text" class="form-control js-mask-phone" name="conjuge-telefone" id="telefone"
                                 [formControlName]="'field18'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 placeholder="( ) 00000-0000" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="dataDeNascimento">Data de nascimento</label>
                          <input type="text" class="form-control js-mask-date" name="conjuge-data-de-nascimento"
                                 [formControlName]="'field19'" [textMask]="{mask: MASKS.data.textMask}"
                                 id="dataDeNascimento" placeholder="Dia / Mês / Ano">
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="nacionalidade">Nacionalidade</label>
                          <input type="text" class="form-control" name="conjuge-nacionalidade" id="nacionalidade"
                                 [formControlName]="'field20'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="rg">RG</label>
                          <input type="text" class="form-control" name="conjuge-rg" id="rg" placeholder="00000000-0"
                                 [formControlName]="'field21'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cpf">CPF</label>
                          <input type="text" class="form-control js-mask-cpf" name="conjuge-cpf" id="cpf"
                                 [formControlName]="'field22'" [textMask]="{mask: MASKS.cpf.textMask}"
                                 placeholder="00000000-0" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="estadoCivil">Estado civíl</label>
                          <select class="form-control" name="conjuge-estado-civil" id="estadoCivil" required
                                  [formControlName]="'field23'">
                            <span></span>
                            <option value=""></option>
                            <option value="Casado">Casado</option>
                            <option value="Solteiro">Solteiro</option>
                            <option value="Divorciado">Divorciado</option>
                            <option value="Viúvo">Viúvo</option>
                          </select>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="profissao">Profissão</label>
                          <input type="text" class="form-control" name="conjuge-profissao" id="profissao"
                                 [formControlName]="'field24'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="localDeTrabalho">Local de trabalho</label>
                          <input type="text" class="form-control" name="conjuge-local-de-trabalho"
                                 [formControlName]="'field25'"
                                 id="localDeTrabalho" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="funcao">Função</label>
                          <input type="text" class="form-control" name="conjuge-funcao" id="funcao" placeholder=" "
                                 [formControlName]="'field26'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="salario">Salário</label>
                          <input type="text" class="form-control js-mask-money" name="conjuge-salario" id="salario"
                                 [formControlName]="'field27'" [textMask]="{mask: MASKS.currency.textMask}"
                                 placeholder=" " required>
                          <span></span>
                        </div>
<!--                        <div class="col-12 col-lg-6 form-group">-->
<!--                          <label for="cartorio">Cartório para reconhecimento de firma</label>-->
<!--                          <input type="text" class="form-control" name="conjuge-cartorio" id="cartorio"-->
<!--                                 [formControlName]="'field29'"-->
<!--                                 placeholder=" " required>-->
<!--                          <span></span>-->
<!--                        </div>-->

                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-3 col-12">INFORMAÇÕES BANCÁRIAS</h6>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="banco">Banco</label>
                          <input type="text" class="form-control" name="banco" id="banco" placeholder=" " required
                                 [formControlName]="'field31'">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="conta">Conta</label>
                          <input type="text" class="form-control" name="conta" id="conta" placeholder=" " required
                                 [formControlName]="'field32'">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="contatoBancario">Contato bancário</label>
                          <input type="text" class="form-control" name="contato-bancario" id="contatoBancario"
                                 [formControlName]="'field33'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-3 col-12">REFERÊNCIA COMERCIAL</h6>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="referenciaComercialempresa">Empresa</label>
                          <input type="text" class="form-control" name="referencia-comercial-empresa"
                                 [formControlName]="'field34'"
                                 id="referenciaComercialempresa" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="referenciaComercialtelefone">Telefone</label>
                          <input type="text" class="form-control js-mask-phone" name="referencia-comercial-telefone"
                                 [formControlName]="'field35'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 id="referenciaComercialtelefone" placeholder=" " required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-1 col-12">DOCUMENTAÇÃO</h6>
                        <p class="col-12">Anexe abaixo cópias da documentação necessária para o andamento do
                          cadastro.</p>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="rg-cpf">RG e CPF</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-rg-cpf" [formControlName]="'field36'">
                            <input type="file" multiple id="rg-cpf"
                                   (change)="onFileChange($event, filename1, form1.controls.field36)">
                            <div>
                              <span class="form-input-file-span" for="formFile">Escolher arquivos</span>
                              <small #filename1></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="filename1.innerText && filename1.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(filename1, form1.controls.field36, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="certidaoCasamento">Certidão de casamento</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-certidao-casamento" [formControlName]="'field37'">
                            <input type="file" multiple id="certidaoCasamento"
                                   (change)="onFileChange($event, filename2, form1.controls.field37)">
                            <div>
                              <span class="form-input-file-span" for="formFile">Escolher arquivos</span>
                              <small #filename2></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="filename2.innerText && filename2.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(filename2, form1.controls.field37, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="holerite">Holerite</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-holerite" [formControlName]="'field38'">
                            <input type="file" multiple id="holerite"
                                   (change)="onFileChange($event, filename3, form1.controls.field38)">
                            <div>
                              <span class="form-input-file-span" for="formFile">Escolher arquivos</span>
                              <small #filename3></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="filename3.innerText && filename3.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(filename3, form1.controls.field38, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="declaracaoIr">Última declaração do IR (renda de 3x o valor do aluguel)</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-declaracao-ir" [formControlName]="'field39'">
                            <input type="file" multiple id="declaracaoIr"
                                   (change)="onFileChange($event, filename4, form1.controls.field39)">
                            <div>
                              <span class="form-input-file-span" for="formFile">Escolher arquivos</span>
                              <small #filename4></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="filename4.innerText && filename4.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(filename4, form1.controls.field39, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <div class="col-6 form-group text-center mt-5 mb-3 mx-auto">
                      <button type="submit" class="btn btn-search btn-rounded" (click)="submit(form1, FORM1_ID)">ENVIAR</button>
                    </div>
                  </form>
                  <div class="form-return"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header" id="heading4" data-toggle="collapse" data-target="#collapse4"
                 [class.collapsed]="!showForm4" (click)="showForm4 = !showForm4"
                 aria-expanded="false" aria-controls="collapse4">
              <div class="row py-3">
                <div class="col-10"><h2 [class.orange-text]="showForm4">CADASTRO DE PESSOA JURÍDICA</h2></div>
                <div class="col-2 text-right">
                  <i class="fas pull-right fa-plus-circle" *ngIf="!showForm4"></i>
                  <i class="fas pull-right orange-text fa-minus-circle" *ngIf="showForm4"></i>
                </div>
              </div>
            </div>
            <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionExample"
                 [class.show]="showForm4">
              <div class="card-body">
                <div class="form-card">
                  <form class="col-12 col-lg-10 offset-lg-1 js-form-to-email" [formGroup]="form2"
                        id="cadastro-pjFormSevicos">
                    <input type="hidden" name="_token" value="Ge1CKCEMAe0J57GvTY6BYpXQvyvGiYrfCzhqxAiV"> <input
                    type="hidden" name="assunto" value="Cadastro Pessoa Jurídica">
                    <input type="hidden" name="success-heading" value="Dados enviados com sucesso!">
                    <input type="hidden" name="success-text"
                           value="Obrigada! Já recebemos seus dados e em breve entraremos em contato para marcarmos uma conversa.">
                    <p>Preencha e envie o formulário abaixo para se cadastrar.</p>
                    <fieldset class="form-fieldset">
                      <div class="row">
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-pjsou-um">Sou um:</label>
                          <div class="form-check form-check-inline main-checkbox" style="margin-left: 5px">
                            <input class="form-check-input" type="radio" id="cadastro-pjlocatario"
                                   [formControlName]="'radio1'"
                                   value="Locatário">
                            <span></span>
                            <label class="form-check-label" for="cadastro-pjlocatario">Locatário</label>
                          </div>
                          <div class="form-check form-check-inline main-checkbox">
                            <input class="form-check-input" type="radio" id="cadastro-pjfiador"
                                   [formControlName]="'radio1'"
                                   value="Fiador">
                            <span></span>
                            <label class="form-check-label" for="cadastro-pjfiador">Fiador</label>
                          </div>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-pjrazao-social">Razão Social</label>
                          <input type="text" class="form-control" placeholder=" " id="cadastro-pjrazao-social"
                                 [formControlName]="'field1'"
                                 name="razao-social">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjemail">E-mail</label>
                          <input type="email" class="form-control" placeholder="Digite seu e-mail aqui" name="email"
                                 [formControlName]="'field2'"
                                 id="cadastro-pjemail" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjtelefone">Telefone/celular</label>
                          <input type="text" class="form-control js-mask-phone" name="telefone"
                                 [formControlName]="'field3'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 id="cadastro-pjtelefone" placeholder="( ) 00000-0000" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjcnpj">CNPJ</label>
                          <input type="text" class="form-control js-mask-cnpj" name="cnpj" id="cadastro-pjcnpj"
                                 [formControlName]="'field4'" [textMask]="{mask: MASKS.cnpj.textMask}"
                                 placeholder="00.000.000/0000-00 " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjie">IE</label>
                          <input type="text" class="form-control" name="ie" id="cadastro-pjie"
                                 [formControlName]="'field5'"
                                 placeholder="Número de inscrição estadual" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjramo-de-atividade">Ramo de atividade</label>
                          <input type="text" class="form-control" name="ramo-de-atividade" [formControlName]="'field6'"
                                 id="cadastro-pjramo-de-atividade" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjcapital-anual">Capital anual</label>
                          <input type="text" class="form-control js-mask-money" name="capital-anual"
                                 [formControlName]="'field7'" [textMask]="{mask: MASKS.currency.textMask}"
                                 id="cadastro-pjcapital-anual" placeholder="R$ " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjcep">CEP</label>
                          <input type="text" class="form-control js-mask-cep" name="cep" id="cadastro-pjcep"
                                 [formControlName]="'field8'" [textMask]="{mask: MASKS.cep.textMask}"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjpais">País</label>
                          <input type="text" class="form-control" name="pais" id="cadastro-pjpais" placeholder=" "
                                 [formControlName]="'field9'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjestado">Estado</label>
                          <input type="text" class="form-control" name="estado" id="cadastro-pjestado" placeholder=" "
                                 [formControlName]="'field10'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjcidade">Cidade</label>
                          <input type="text" class="form-control" name="cidade" id="cadastro-pjcidade" placeholder=" "
                                 [formControlName]="'field11'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-pjendereco">Endereço</label>
                          <input type="text" class="form-control" name="endereco" id="cadastro-pjendereco"
                                 [formControlName]="'field12'"
                                 placeholder="Digite aqui o endereço" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjbairro">Bairro</label>
                          <input type="text" class="form-control" name="bairro" id="cadastro-pjbairro"
                                 [formControlName]="'field13'"
                                 placeholder="Nome do bairro" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjnumero">Número</label>
                          <input type="text" class="form-control" name="numero" id="cadastro-pjnumero"
                                 [formControlName]="'field14'"
                                 placeholder="Número do imóvel" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjcomplemento">Complemento</label>
                          <input type="text" class="form-control" name="complemento" id="cadastro-pjcomplemento"
                                 [formControlName]="'field15'"
                                 placeholder="Ex: Bloco xxx , Número xxx" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-corretor">Corretor</label>
                          <select class="form-control field-corretor clickable" name="estado-civil" id="rand2-corretor"
                                  required
                                  [formControlName]="'field46'">
                            <span></span>
                            <option value=""></option>
                            <option value="{{corretor.email_do_corretor}}"
                                    *ngFor="let corretor of corretores">{{corretor.nome_do_corretor}}</option>
                          </select>
                        </div>
                      </div>
                    </fieldset>


                    <fieldset class="form-fieldset">
                      <div class="row justify-content-center">
                        <h6 class="mt-3 mb-3 col-12">Sócio 1</h6>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Nome</label>
                          <input type="text" class="form-control" name="banco" id="" placeholder=" "
                                 [formControlName]="'field28'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">CPF</label>
                          <input type="text" class="form-control" name="conta" placeholder=" "
                                 [formControlName]="'field29'" [textMask]="{mask: MASKS.cpf.textMask}"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">RG</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field30'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Data Nascimento</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field31'"
                                 placeholder=" " [textMask]="{mask: MASKS.data.textMask}" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Nacionalidade</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field32'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Estado Civil</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field33'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Profissão</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field34'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">E-mail</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field35'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Telefone/Celular</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field36'"
                                 placeholder=" " [textMask]="{mask: MASKS.telefone.textMaskFunction}" required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>


                    <fieldset class="form-fieldset">
                      <div class="row justify-content-center">
                        <h6 class="mt-3 mb-3 col-12">Sócio 2</h6>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Nome</label>
                          <input type="text" class="form-control" name="banco" id="" placeholder=" "
                                 [formControlName]="'field37'"
                          >
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">CPF</label>
                          <input type="text" class="form-control" name="conta" placeholder=" "
                                 [formControlName]="'field38'" [textMask]="{mask: MASKS.cpf.textMask}">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">RG</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field39'"
                                 placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Data Nascimento</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field40'"
                                 placeholder=" " [textMask]="{mask: MASKS.data.textMask}">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Nacionalidade</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field41'"
                                 placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Estado Civil</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field42'"
                                 placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Profissão</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field43'"
                                 placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">E-mail</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field44'"
                                 placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="">Telefone/Celular</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field45'"
                                 placeholder=" " [textMask]="{mask: MASKS.telefone.textMaskFunction}">
                          <span></span>
                        </div>
                      </div>
                    </fieldset>


                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-3 col-12">INFORMAÇÕES BANCÁRIAS</h6>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjbanco">Banco</label>
                          <input type="text" class="form-control" name="banco" id="cadastro-pjbanco" placeholder=" "
                                 [formControlName]="'field16'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjconta">Conta</label>
                          <input type="text" class="form-control" name="conta" id="cadastro-pjconta" placeholder=" "
                                 [formControlName]="'field17'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-pjcontatoBancario">Contato bancário</label>
                          <input type="text" class="form-control" name="contato-bancario" [formControlName]="'field18'"
                                 id="cadastro-pjcontatoBancario" placeholder=" " required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-3 col-12">REFERÊNCIAIS COMERCIAIS</h6>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjreferenciaComercialempresa">Empresa 1</label>
                          <input type="text" class="form-control" name="referencia-comercial-empresa"
                                 [formControlName]="'field19'"
                                 id="cadastro-pjreferenciaComercialempresa" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjreferenciaComercialtelefone">Telefone</label>
                          <input type="text" class="form-control js-mask-phone" name="referencia-comercial-telefone"
                                 [formControlName]="'field20'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 id="cadastro-pjreferenciaComercialtelefone" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjreferenciaComercialempresa">Empresa 2</label>
                          <input type="text" class="form-control" name="referencia-comercial-empresa-2"
                                 [formControlName]="'field21'"
                                 id="cadastro-pjreferenciaComercialempresa" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-pjreferenciaComercialtelefone">Telefone</label>
                          <input type="text" class="form-control js-mask-phone" name="referencia-comercial-telefone-2"
                                 [formControlName]="'field22'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 id="cadastro-pjreferenciaComercialtelefone" placeholder=" " required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-1 col-12">DOCUMENTAÇÃO</h6>
                        <p class="col-12">Anexe abaixo cópias da documentação necessária para o andamento do
                          cadastro.</p>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-pjcartao-cnpj">Cartão CNPJ</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-cartao-cnpj" [formControlName]="'field23'">
                            <input type="file" multiple id="cadastro-pjcartao-cnpj"
                                   (change)="onFileChange($event, form2filename01, form2.controls.field23)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-pjformFile">Escolher arquivos</span>
                              <small #form2filename01></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form2filename01.innerText && form2filename01.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form2filename01, form2.controls.field23, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-pjcontrato-social">Contrato social</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-certidao-casamento" [formControlName]="'field24'">
                            <input type="file" multiple id="cadastro-pjcontrato-social"
                                   (change)="onFileChange($event, form2filename02, form2.controls.field24)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-pjformFile">Escolher arquivos</span>
                              <small #form2filename02></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form2filename02.innerText && form2filename02.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form2filename02, form2.controls.field24, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-pjcpf-e-rg-dos-socios">CPF/RG dos sócios</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-cpf-e-rg-dos-socios" [formControlName]="'field25'">
                            <input type="file" multiple id="cadastro-pjcpf-e-rg-dos-socios"
                                   (change)="onFileChange($event, form2filename1, form2.controls.field25)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-pjformFile">Escolher arquivos</span>
                              <small #form2filename1></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form3filename1.innerText && form3filename1.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form3filename1, form2.controls.field25, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-pjultimo-balanco-contabil">Último balanço contábil (com C.R.C)</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-ultimo-balanco-contabil" [formControlName]="'field26'">
                            <input type="file" multiple id="cadastro-pjultimo-balanco-contabil"
                                   (change)="onFileChange($event, form2filename2, form2.controls.field26)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-pjformFile">Escolher arquivos</span>
                              <small #form2filename2></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form2filename2.innerText && form2filename2.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form2filename2, form2.controls.field26, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-pjprocuracao-para-o-assinante">Procuração para o assinante (caso não
                            sócio) </label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-procuracao-para-o-assinante" [formControlName]="'field27'">
                            <input type="file" multiple id="cadastro-pjprocuracao-para-o-assinante"
                                   (change)="onFileChange($event, form2filename3, form2.controls.field27)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-pjformFile">Escolher arquivos</span>
                              <small #form2filename3></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form2filename3.innerText && form2filename3.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form2filename3, form2.controls.field27, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <div class="col-6 form-group text-center mt-5 mb-3 mx-auto">
                      <button type="submit" class="btn btn-search btn-rounded" (click)="submit(form2, FORM2_ID)">ENVIAR</button>
                    </div>
                  </form>
                  <div class="form-return"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header" id="heading5" data-toggle="collapse" data-target="#collapse5"
                 [class.collapsed]="!showForm5" (click)="showForm5 = !showForm5"
                 aria-expanded="false" aria-controls="collapse5">
              <div class="row py-3">
                <div class="col-10"><h2 [class.orange-text]="showForm5">LOCADOR</h2></div>
                <div class="col-2 text-right">
                  <i class="fas pull-right fa-plus-circle" *ngIf="!showForm5"></i>
                  <i class="fas pull-right orange-text fa-minus-circle" *ngIf="showForm5"></i>
                </div>
              </div>
            </div>
            <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionExample"
                 [class.show]="showForm5">
              <div class="card-body">
                <div class="form-card">
                  <form class="col-12 col-lg-10 offset-lg-1 js-form-to-email" [formGroup]="form3"
                        id="cadastro-locadorFormSevicos">
                    <h6>CADASTRO DO LOCADOR</h6>
                    <p>Preencha e envie o formulário abaixo para se cadastrar.</p>
                    <fieldset class="form-fieldset">
                      <div class="row justify-content-center">
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorsou-um">Sou um:</label>
                          <div class="form-check form-check-inline main-checkbox" style="margin-left: 5px">
                            <input class="form-check-input" type="radio" id="cadastro-locadorlocatario"
                                   [formControlName]="'radio1'"
                                   value="Locador Pessoa Física">
                            <span></span>
                            <label class="form-check-label" for="cadastro-locadorlocatario">Locador Pessoa
                              Física</label>
                          </div>
                          <div class="form-check form-check-inline main-checkbox">
                            <input class="form-check-input" type="radio" id="cadastro-locadorfiador"
                                   [formControlName]="'radio1'"
                                   value="Locador Pessoa Jurídica">
                            <span></span>
                            <label class="form-check-label" for="cadastro-locadorfiador">Locador Pessoa
                              Jurídica</label>
                          </div>
                        </div>
                        <div class="col-12">
                          <h6>DADOS DO IMÓVEL</h6>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorcep">CEP</label>
                          <input type="text" class="form-control js-mask-cep" name="cep" id="cadastro-locadorcep"
                                 [formControlName]="'field1'" [textMask]="{mask: MASKS.cep.textMask}"
                                 placeholder="00000-000" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorpais">País</label>
                          <input type="text" class="form-control" name="pais" id="cadastro-locadorpais"
                                 [formControlName]="'field2'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorestado">Estado</label>
                          <input type="text" class="form-control" name="estado" id="cadastro-locadorestado"
                                 [formControlName]="'field3'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorcidade">Cidade</label>
                          <input type="text" class="form-control" name="cidade" id="cadastro-locadorcidade"
                                 [formControlName]="'field4'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorendereco">Endereço</label>
                          <input type="text" class="form-control" name="endereco" id="cadastro-locadorendereco"
                                 [formControlName]="'field5'"
                                 placeholder="Digite aqui o endereço" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorbairro">Bairro</label>
                          <input type="text" class="form-control" name="bairro" id="cadastro-locadorbairro"
                                 [formControlName]="'field6'"
                                 placeholder="Nome do bairro" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadornumero">Número</label>
                          <input type="text" class="form-control" name="numero" id="cadastro-locadornumero"
                                 [formControlName]="'field7'"
                                 placeholder="Número do imóvel" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorcomplemento">Complemento</label>
                          <input type="text" class="form-control" name="complemento" id="cadastro-locadorcomplemento"
                                 [formControlName]="'field8'"
                                 placeholder="Ex: Bloco xxx , Número xxx" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorcod-iptu">Código IPTU</label>
                          <input type="text" class="form-control" name="cod-iptu" id="cadastro-locadorcod-iptu"
                                 [formControlName]="'field9'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorcod-cpfl">Código CPFL</label>
                          <input type="text" class="form-control" name="cod-cpfl" id="cadastro-locadorcod-cpfl"
                                 [formControlName]="'field10'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorcod-sanasa">Código SANASA</label>
                          <input type="text" class="form-control" name="cod-sanasa" id="cadastro-locadorcod-sanasa"
                                 [formControlName]="'field11'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorcod-gas">Código de gás</label>
                          <input type="text" class="form-control" name="cod-gas" id="cadastro-locadorcod-gas"
                                 [formControlName]="'field12'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorcod-pintura-nova">Imóvel com pintura nova?</label>
                          <select class="form-control" name="estado-civil" id="cadastro-locadorcod-pintura-nova"
                                  required
                                  [formControlName]="'field25'">
                            <span></span>
                            <option value=""></option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                          </select>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locador-adm-cond">Administradora do condomínio</label>
                          <input type="text" class="form-control" id="cadastro-locador-adm-cond"
                                 [formControlName]="'field55'"
                                 placeholder=" " required>
                          <span></span>
                        </div>

                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-3 col-12">DADOS DO LOCADOR</h6>

                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadornome">Nome</label>
                          <input type="text" class="form-control" placeholder="Nome Completo"
                                 [formControlName]="'field13'"
                                 id="cadastro-locadornome" name="nome" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadoremail">E-mail</label>
                          <input type="email" class="form-control" placeholder="Digite seu e-mail aqui" name="email"
                                 [formControlName]="'field14'"
                                 id="cadastro-locadoremail" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadortelefone">Telefone/celular</label>
                          <input type="text" class="form-control js-mask-phone" name="telefone"
                                 [formControlName]="'field15'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 id="cadastro-locadortelefone" placeholder="( ) 00000-0000" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadordataDeNascimento">Data de nascimento</label>
                          <input type="text" class="form-control js-mask-date" name="data-de-nascimento"
                                 [formControlName]="'field16'" [textMask]="{mask: MASKS.data.textMask}"
                                 id="cadastro-locadordataDeNascimento" placeholder="Dia / Mês / Ano" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadornacionalidade">Nacionalidade</label>
                          <input type="text" class="form-control" name="nacionalidade" [formControlName]="'field17'"
                                 id="cadastro-locadornacionalidade" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorrg">RG</label>
                          <input type="text" class="form-control" name="rg" id="cadastro-locadorrg"
                                 [formControlName]="'field18'"
                                 placeholder="00000000-0" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorcpf">CPF</label>
                          <input type="text" class="form-control js-mask-cpf" name="cpf" id="cadastro-locadorcpf"
                                 [formControlName]="'field19'" [textMask]="{mask: MASKS.cpf.textMask}"
                                 placeholder="000.000.000-00" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorestadoCivil">Estado civíl</label>
                          <select class="form-control" name="estado-civil" id="cadastro-locadorestadoCivil" required
                                  [formControlName]="'field20'">
                            <span></span>
                            <option value=""></option>
                            <option value="Casado">Casado</option>
                            <option value="Solteiro">Solteiro</option>
                            <option value="Divorciado">Divorciado</option>
                            <option value="Viúvo">Viúvo</option>
                          </select>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorprofissao">Profissão</label>
                          <input type="text" class="form-control" name="profissao" id="cadastro-locadorprofissao"
                                 [formControlName]="'field21'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorlocalDeTrabalho">Local de trabalho</label>
                          <input type="text" class="form-control" name="local-de-Trabalho" [formControlName]="'field22'"
                                 id="cadastro-locadorlocalDeTrabalho" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorfuncao">Função</label>
                          <input type="text" class="form-control" name="funcao" id="cadastro-locadorfuncao"
                                 [formControlName]="'field23'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorendereco-do-trabalho">Endereço do locador</label>
                          <input type="text" class="form-control" name="endereco-do-trabalho"
                                 [formControlName]="'field24'"
                                 id="cadastro-locadorendereco-do-trabalho" placeholder=" " required>
                          <span></span>
                        </div>

                      </div>
                    </fieldset>


                    <fieldset class="form-fieldset">
                      <div class="row justify-content-center">
                        <h6 class="mt-3 mb-3 col-12">DADOS DO CONJUGUE</h6>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadornome">Nome</label>
                          <input type="text" class="form-control" placeholder="Nome Completo"
                                 [formControlName]="'field26'"
                                 id="cadastro-locadornome" name="conjuge-nome">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadortelefone">Telefone/celular</label>
                          <input type="text" class="form-control js-mask-phone" name="conjuge-telefone"
                                 [formControlName]="'field27'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 id="cadastro-locadortelefone" placeholder="( ) 00000-0000">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadordataDeNascimento">Data de nascimento</label>
                          <input type="text" class="form-control js-mask-date" name="conjuge-data-de-nascimento"
                                 [formControlName]="'field28'" [textMask]="{mask: MASKS.data.textMask}"
                                 id="cadastro-locadordataDeNascimento" placeholder="Dia / Mês / Ano">
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadornacionalidade">Nacionalidade</label>
                          <input type="text" class="form-control" name="conjuge-nacionalidade"
                                 [formControlName]="'field29'"
                                 id="cadastro-locadornacionalidade" placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorrg">RG</label>
                          <input type="text" class="form-control" name="conjuge-rg" id="cadastro-locadorrg"
                                 [formControlName]="'field30'"
                                 placeholder="00000000-0">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorcpf">CPF</label>
                          <input type="text" class="form-control js-mask-cpf" name="conjuge-cpf"
                                 [formControlName]="'field31'" [textMask]="{mask: MASKS.cpf.textMask}"
                                 id="cadastro-locadorcpf" placeholder="00000000-0">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorestadoCivil">Estado civíl</label>
                          <select class="form-control" name="conjuge-estado-civil" id="cadastro-locadorestadoCivil"
                                  [formControlName]="'field32'"
                          >
                            <span></span>
                            <option value=""></option>
                            <option value="Casado">Casado</option>
                            <option value="Solteiro">Solteiro</option>
                            <option value="Divorciado">Divorciado</option>
                            <option value="Viúvo">Viúvo</option>
                          </select>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorprofissao">Profissão</label>
                          <input type="text" class="form-control" name="conjuge-profissao" [formControlName]="'field33'"
                                 id="cadastro-locadorprofissao" placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorlocalDeTrabalho">Local de trabalho</label>
                          <input type="text" class="form-control" name="conjuge-local-de-trabalho"
                                 [formControlName]="'field34'"
                                 id="cadastro-locadorlocalDeTrabalho" placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorfuncao">Função</label>
                          <input type="text" class="form-control" name="conjuge-funcao" id="cadastro-locadorfuncao"
                                 [formControlName]="'field35'"
                                 placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorsalario">Salário</label>
                          <input type="text" class="form-control js-mask-money" name="conjuge-salario"
                                 [formControlName]="'field36'" [textMask]="{mask: MASKS.currency.textMask}"
                                 id="cadastro-locadorsalario" placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorenderecoDoTrabalho">Endereço do trabalho</label>
                          <input type="text" class="form-control" name="conjuge-endereco-do-trabalho"
                                 [formControlName]="'field37'"
                                 id="cadastro-locadorenderecoDoTrabalho" placeholder=" ">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locador-irf-conjuge">Deseja declarar 50% do IR no nome do
                            cônjuge?</label>
                          <select class="form-control" name="estado-civil" id="cadastro-locador-irf-conjuge"
                                  [formControlName]="'field45'">
                            <option value=""></option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                          </select>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-3 col-12">INFORMAÇÕES BANCÁRIAS</h6>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadorbanco">Banco</label>
                          <input type="text" class="form-control" name="banco" id="cadastro-locadorbanco"
                                 [formControlName]="'field40'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-2 form-group">
                          <label for="cadastro-locadoragencia">Agência</label>
                          <input type="text" class="form-control" name="agencia" id="cadastro-locadoragencia"
                                 [formControlName]="'field41'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-4 form-group">
                          <label for="cadastro-locadorconta">Conta</label>
                          <input type="text" class="form-control" name="conta" id="cadastro-locadorconta"
                                 [formControlName]="'field42'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadortipo-de-conta">Tipo de conta</label>
                          <input type="text" class="form-control" name="tipo-de-conta" [formControlName]="'field43'"
                                 id="cadastro-locadortipo-de-conta" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="cadastro-locadortitulo">Titular</label>
                          <input type="text" class="form-control" name="titulo" id="cadastro-locadortitulo"
                                 [formControlName]="'field44'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-1 col-12">DOCUMENTAÇÃO</h6>
                        <p class="col-12">Anexe abaixo cópias da documentação necessária para o andamento do
                          cadastro.</p>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorcarne-iptu-do-imovel">Carnê do IPTU do imóvel</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-carne-iptu-do-imovel" [formControlName]="'field47'">
                            <input type="file" multiple id="cadastro-locadorcartao-cnpj"
                                   (change)="onFileChange($event, form3filename1, form3.controls.field47)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-locadorformFile">Escolher arquivos</span>
                              <small #form3filename1></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form3filename1.innerText && form3filename1.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form3filename1, form3.controls.field47, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorplanta-do-imovel">Planta do imóvel</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-planta-do-imovel" [formControlName]="'field48'">
                            <input type="file" multiple id="cadastro-locadorplanta-do-imovel"
                                   (change)="onFileChange($event, form3filename2, form3.controls.field48)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-locadorformFile">Escolher arquivos</span>
                              <small #form3filename2></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form3filename2.innerText && form3filename2.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form3filename2, form3.controls.field48, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadormatrituca-do-imovel">Matrícula do imóvel</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-matrituca-do-imovel" [formControlName]="'field49'">
                            <input type="file" multiple id="cadastro-locadormatrituca-do-imovel"
                                   (change)="onFileChange($event, form3filename3, form3.controls.field49)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-locadorformFile">Escolher arquivos</span>
                              <small #form3filename3></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form3filename3.innerText && form3filename3.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form3filename3, form3.controls.field49, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorrg-e-cpf">RG e CPF</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-rg-e-cpf" [formControlName]="'field50'">
                            <input type="file" multiple id="cadastro-locadorrg-e-cpf"
                                   (change)="onFileChange($event, form3filename4, form3.controls.field50)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-locadorformFile">Escolher arquivos</span>
                              <small #form3filename4></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form3filename4.innerText && form3filename4.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form3filename4, form3.controls.field50, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorcomprovante-de-endereco">Comprovante de endereço</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-comprovante-de-endereco" [formControlName]="'field51'">
                            <input type="file" multiple id="cadastro-locadorcomprovante-de-endereco"
                                   (change)="onFileChange($event, form3filename5, form3.controls.field51)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-locadorformFile">Escolher arquivos</span>
                              <small #form3filename5></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form3filename5.innerText && form3filename5.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form3filename5, form3.controls.field51, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorultima-conta-de-luz">Última conta de luz</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-ultima-conta-de-luz" [formControlName]="'field52'">
                            <input type="file" multiple id="cadastro-locadorultima-conta-de-luz"
                                   (change)="onFileChange($event, form3filename6, form3.controls.field52)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-locadorformFile">Escolher arquivos</span>
                              <small #form3filename6></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form3filename6.innerText && form3filename6.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form3filename6, form3.controls.field52, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorultima-conta-de-agua">Última conta de água</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-ultima-conta-de-agua" [formControlName]="'field53'">
                            <input type="file" multiple id="cadastro-locadorultima-conta-de-agua"
                                   (change)="onFileChange($event, form3filename7, form3.controls.field53)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-locadorformFile">Escolher arquivos</span>
                              <small #form3filename7></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form3filename7.innerText && form3filename7.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form3filename7, form3.controls.field53, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="cadastro-locadorultima-conta-de-gas">Última conta de gás</label>
                          <label class="form-input-file">
                            <input type="hidden" name="doc-ultima-conta-de-gas" [formControlName]="'field54'">
                            <input type="file" multiple id="cadastro-locadorultima-conta-de-gas"
                                   (change)="onFileChange($event, form3filename8, form3.controls.field54)">
                            <div>
                              <span class="form-input-file-span" for="cadastro-locadorformFile">Escolher arquivos</span>
                              <small #form3filename8></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form3filename8.innerText && form3filename8.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form3filename8, form3.controls.field54, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <div class="col-6 form-group text-center mt-5 mb-3 mx-auto">
                      <button type="submit" class="btn btn-search btn-rounded" (click)="submit(form3,  FORM3_ID)">ENVIAR</button>
                    </div>
                  </form>
                  <div class="form-return"></div>
                </div>
              </div>
            </div>
          </div>


          <div class="card">
            <div class="card-header" id="heading6" data-toggle="collapse" data-target="#collapse6"
                 [class.collapsed]="!showForm6" (click)="showForm6 = !showForm6"
                 aria-expanded="false" aria-controls="collapse6">
              <div class="row py-3">
                <div class="col-10"><h2 [class.orange-text]="showForm6">AVISO DE DESOCUPAÇÃO</h2></div>
                <div class="col-2 text-right">
                  <i class="fas pull-right fa-plus-circle" *ngIf="!showForm6"></i>
                  <i class="fas pull-right orange-text fa-minus-circle" *ngIf="showForm6"></i>
                </div>
              </div>
            </div>
            <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionExample"
                 [class.show]="showForm6">
              <div class="card-body">
                <div class="form-card">
                  <div class="form-fieldset col-12 col-lg-10 offset-lg-1 mb-0 mb-md-5">
                    <h6>Procedimentos para a Rescisão Contratual: IMPORTANTE:</h6>
                    <p>-Quando a Locação for de imóvel em Edifício ou Condomínio, o locatário
                      deve solicitar junto a administradora do condomínio, a autorização de
                      mudança;</p>

                    <p>- Ainda dentro do prazo dos 30 (trinta) dias do Aviso da Desocupação, o
                      locatário deverá entrar em contato com a Next (Zilda) com 4(quatro) dias
                      de antecedência, após o imóvel livre e desembaraçado de pessoas e
                      coisas, com pintura nova, conforme vistoria inicial (portas, batentes,
                      paredes, tetos, gola de gesso/sanca) e outros locais (quando for o caso),
                      faxina, reparos (quando for o caso), para agendarmos a vistoria;</p>

                    <p>- Apresentar as 3 (três) últimas contas de condomínio (boletos e
                      comprovantes de pagamento), CPFL (contas e comprovantes de
                      pagamento), Gás (se houver), Sanasa (se houver); obrigatório</p>

                    <p>- Não solicitar o desligamento da luz, água (se houver) e gás (se houver)
                      junto as empresas responsáveis. A transferência de titularidade, caso as
                      contas estejam em nome do locatário, serão feitas pela Next ou no caso
                      da proprietária ser pessoa jurídica, pelo seu representante.</p>

                    <p>- Caso tenha alguma assinatura de TV, internet e telefone (exemplo: NET,
                      Sky, Telefônica, etc), que tenha sido solicitada pelo locatário, a solicitação
                      de desligamento e retirada do aparelho deve ser feita pelo mesmo antes
                      da vistoria.</p>

                    <p>- Após a vistoria, caso seja aprovada, o locatário fará a entrega das chaves,
                      controle do portão, cartão do carrinho de compras, etc., aqui na Next.
                      Após, dentro do prazo de 5 (cinco) dias úteis, será preparada a Rescisão do
                      Contrato e enviada via e-mail para os acertos finais, que deverão ser feitos
                      através de boletos bancários;</p>


                    <p>Ressaltamos que, caso a vistoria não seja aprovada, continuarão correndo
                      por conta do locatário, aluguel e acessórios da locação.</p>
                  </div>
                  <form class="col-12 col-lg-10 offset-lg-1 js-form-to-email" [formGroup]="form4"
                        id="aviso-desocupacaoFormSevicos">
                    <h6>FORMULÁRIO</h6>
                    <p>Preencha os campos abaixo e envie o formulário para dar início ao processo de desocupação:</p>
                    <fieldset class="form-fieldset">
                      <div class="row">
                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="nome">Nome</label>
                          <input type="text" class="form-control" placeholder="Nome Completo" id="nome" name="nome"
                                 [formControlName]="'field1'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="email">E-mail</label>
                          <input type="email" class="form-control" placeholder="Digite seu e-mail aqui" name="email"
                                 [formControlName]="'field2'"
                                 id="email" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="telefone">Telefone/celular</label>
                          <input type="text" class="form-control js-mask-phone" name="telefone" id="telefone"
                                 [formControlName]="'field3'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 placeholder="( ) 00000-0000" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaotipo-de-imovel">Tipo de imóvel</label>
                          <select class="form-control" name="tipo-de-imovel" id="aviso-desocupacaotipo-de-imovel"
                                  [formControlName]="'field4'"
                                  required>
                            <span></span>
                            <option value=""></option>
                            <option value="Apartamento">Apartamento</option>
                            <option value="Loft">Loft</option>
                            <option value="Flat">Flat</option>
                            <option value="Casa">Casa</option>
                          </select>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaovalor-atual-do-aluguel">Valor atual do aluguel</label>
                          <input type="text" class="form-control js-mask-money" name="valor-atual-do-aluguel"
                                 [formControlName]="'field5'" [textMask]="{mask: MASKS.currency.textMask}"
                                 id="aviso-desocupacaovalor-atual-do-aluguel" placeholder="R$" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaonome-do-edificio-ou-condominio">Nome do
                            edifício/condomínio</label>
                          <input type="text" class="form-control" name="nome-do-edificio-ou-condominio"
                                 [formControlName]="'field6'"
                                 id="aviso-desocupacaonome-do-edificio-ou-condominio" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaonome-da-administradora-do-condominio">Nome da administradora do
                            condomínio</label>
                          <input type="text" class="form-control" name="nome-da-administradora-do-condominio"
                                 [formControlName]="'field7'"
                                 id="aviso-desocupacaonome-da-administradora-do-condominio" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaodata-de-entrada">Data de entrada</label>
                          <input type="text" class="form-control js-mask-date" name="data-de-entrada"
                                 [formControlName]="'field8'" [textMask]="{mask: MASKS.data.textMask}"
                                 id="aviso-desocupacaodata-de-entrada" placeholder="Dia / Mês / Ano" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaodata-prevista-para-a-desocupacao">Data prevista para a
                            desocupação</label>
                          <input type="text" class="form-control js-mask-date" name="data-prevista-para-a-desocupacao"
                                 [formControlName]="'field9'" [textMask]="{mask: MASKS.data.textMask}"
                                 id="aviso-desocupacaodata-prevista-para-a-desocupacao" placeholder="Dia / Mês / Ano"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaovalor-do-ultimo-condominio-pago">Valor do último condomínio
                            pago</label>
                          <input type="text" class="form-control js-mask-money" name="valor-do-ultimo-condominio-pago"
                                 [formControlName]="'field10'" [textMask]="{mask: MASKS.currency.textMask}"
                                 id="aviso-desocupacaovalor-do-ultimo-condominio-pago" placeholder="R$" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaovalor-do-ultimo-iptu-pago">Valor do último IPTU pago</label>
                          <input type="text" class="form-control js-mask-money" name="valor-do-ultimo-iptu-pago"
                                 [formControlName]="'field11'" [textMask]="{mask: MASKS.currency.textMask}"
                                 id="aviso-desocupacaovalor-do-ultimo-iptu-pago" placeholder="R$" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaoendereco-do-imovel">Endereço do imóvel</label>
                          <input type="text" class="form-control" name="endereco-do-imovel"
                                 [formControlName]="'field12'"
                                 id="aviso-desocupacaoendereco-do-imovel"
                                 placeholder="Ex: Av. xxxx, Bairro xxx, Nº xx, Campinas- SP. Bloco xx, apartamento xxx "
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaomotivo-da-desocupacao">Motivo da desocupação</label>
                          <textarea class="form-control" name="motivo-da-desocupacao" [formControlName]="'field13'"
                                    id="aviso-desocupacaomotivo-da-desocupacao" placeholder="Opcional"></textarea>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <div class="col-12 form-group text-center mt-5 mb-3 mx-auto">
                      <button type="submit" class="btn btn-search btn-rounded" (click)="submit(form4, FORM4_ID)">ENVIAR</button>
                    </div>
                  </form>
                  <div class="form-return"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header" id="heading7" data-toggle="collapse" data-target="#collapse7"
                 [class.collapsed]="!showForm7" (click)="showForm7 = !showForm7"
                 aria-expanded="false" aria-controls="collapse7">
              <div class="row py-3">
                <div class="col-10"><h2 [class.orange-text]="showForm7">ANUNCIE O SEU IMÓVEL</h2></div>
                <div class="col-2 text-right">
                  <i class="fas pull-right fa-plus-circle" *ngIf="!showForm7"></i>
                  <i class="fas pull-right orange-text fa-minus-circle" *ngIf="showForm7"></i>
                </div>
              </div>
            </div>
            <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordionExample"
                 [class.show]="showForm7">
              <div class="card-body">
                <div class="form-card">
                  <div class="col-12 col-lg-10 offset-lg-1 mb-5 pb-2 form-fieldset">
                    <p>Preencha e envie o formulário abaixo para vender ou alugar o seu imóvel com a NEXT.</p>
                  </div>
                  <form class="col-12 col-lg-10 offset-lg-1 js-form-to-email" [formGroup]="form5"
                        id="aviso-desocupacaoFormSevicos">
                    <h6>DADOS DO PROPRIETÁRIO</h6>
                    <fieldset class="form-fieldset">
                      <div class="row">
                        <div class="col-12 col-lg-12 form-group">
                          <label for="aviso-desocupacaonome">Nome</label>
                          <input type="text" class="form-control" placeholder="Nome Completo" id="nome"
                                 [formControlName]="'field1'"
                                 required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaoemail">E-mail</label>
                          <input type="email" class="form-control" placeholder="Digite seu e-mail aqui" name="email"
                                 [formControlName]="'field2'"
                                 id="email" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaotelefone">Telefone/celular</label>
                          <input type="text" class="form-control js-mask-phone" name="telefone"
                                 [formControlName]="'field3'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 id="aviso-desocupacaotelefone" placeholder="( ) 00000-0000" required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="col-12">LOCALIZAÇÃO</h6>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaocep">CEP</label>
                          <input type="text" class="form-control js-mask-cep" name="cep" id="aviso-desocupacaocep"
                                 [formControlName]="'field4'" [textMask]="{mask: MASKS.cep.textMask}"
                                 placeholder="00000-000" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaopais">País</label>
                          <input type="text" class="form-control" name="pais" id="aviso-desocupacaopais"
                                 [formControlName]="'field5'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaoestado">Estado</label>
                          <input type="text" class="form-control" name="estado" id="aviso-desocupacaoestado"
                                 [formControlName]="'field6'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaocidade">Cidade</label>
                          <input type="text" class="form-control" name="cidade" id="aviso-desocupacaocidade"
                                 [formControlName]="'field7'"
                                 placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="aviso-desocupacaoendereco">Endereço</label>
                          <input type="text" class="form-control" name="endereco" id="aviso-desocupacaoendereco"
                                 [formControlName]="'field8'"
                                 placeholder="Digite aqui o endereço" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaobairro">Bairro</label>
                          <input type="text" class="form-control" name="bairro" id="aviso-desocupacaobairro"
                                 [formControlName]="'field9'"
                                 placeholder="Nome do bairro" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaonumero">Número</label>
                          <input type="text" class="form-control" name="numero" id="aviso-desocupacaonumero"
                                 [formControlName]="'field10'"
                                 placeholder="Número do imóvel" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="aviso-desocupacaocomplemento">Complemento</label>
                          <input type="text" class="form-control" name="complemento" id="aviso-desocupacaocomplemento"
                                 [formControlName]="'field11'"
                                 placeholder="Ex: Bloco xxx , Número xxx" required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="col-12">CARACTERÍSTICAS</h6>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaotipo-de-imovel">Tipo de imóvel</label>
                          <select class="form-control" name="estado-civil" id="aviso-desocupacaotipo-de-imovel"
                                  [formControlName]="'field12'"
                                  required>
                            <span></span>
                            <option value=""></option>
                            <option value="Casa">Casa</option>
                            <option value="Apartamento">Apartamento</option>
                            <option value="Terreno">Terreno</option>
                            <option value="Outro">Outro</option>
                          </select>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaoandar">Andar</label>
                          <input type="text" class="form-control" placeholder="Número do andar" name="andar"
                                 [formControlName]="'field13'"
                                 id="aviso-desocupacaoandar" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 d-flex">
                          <div class="services-form-line d-flex flex-column mt-md-3">
                            <div class="form-group row">
                              <div class="col-md-8 d-flex">
                                <label class="d-flex align-items-center" for="aviso-desocupacaon-domitorios">Nº de
                                  dormitórios</label>
                              </div>
                              <div class="col-md-4">
                                <input type="text" class="form-control text-center" placeholder="00"
                                       [formControlName]="'field14'"
                                       name="n-domitorios" id="aviso-desocupacaon-domitorios" required>
                                <span></span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-md-8 d-flex">
                                <label class="d-flex align-items-center" for="aviso-desocupacaon-suites">Nº de
                                  suites</label>
                              </div>
                              <div class="col-md-4">
                                <input type="text" class="form-control text-center" placeholder="00" name="n-suites"
                                       [formControlName]="'field15'"
                                       id="aviso-desocupacaon-suites" required>
                                <span></span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-md-8 d-flex">
                                <label class="d-flex align-items-center" for="aviso-desocupacaon-banheiros">Nº de
                                  banheiro</label>
                              </div>
                              <div class="col-md-4">
                                <input type="text" class="form-control text-center" placeholder="00"
                                       [formControlName]="'field16'"
                                       name="n-banheiros" id="aviso-desocupacaon-banheiros" required>
                                <span></span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-md-8 d-flex">
                                <label class="d-flex align-items-center" for="aviso-desocupacaon-salas">Nº de
                                  salas</label>
                              </div>
                              <div class="col-md-4">
                                <input type="text" class="form-control text-center" placeholder="00" name="n-salas"
                                       [formControlName]="'field17'"
                                       id="aviso-desocupacaon-salas" required>
                                <span></span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-md-8 d-flex">
                                <label class="d-flex align-items-center" for="aviso-desocupacaon-de-vagas">Nº de
                                  vagas</label>
                              </div>
                              <div class="col-md-4">
                                <input type="text" class="form-control text-center" placeholder="00" name="n-de-vagas"
                                       [formControlName]="'field18'"
                                       id="aviso-desocupacaon-de-vagas" required>
                                <span></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-lg-6 d-flex">
                          <div class="services-form-line d-flex flex-column justify-content-between mt-md-3">
                            <div class="form-group row">
                              <label for="aviso-desocupacaoarmarios-imbutidos-sim" class="col-12">Armários
                                embutidos</label>
                              <div class="col-12">
                                <div class="form-check form-check-inline main-checkbox">
                                  <input class="form-check-input" type="radio" [formControlName]="'field19'"
                                         id="aviso-desocupacaoarmarios-imbutidos-sim" value="Sim">
                                  <span></span>
                                  <label class="form-check-label"
                                         for="aviso-desocupacaoarmarios-imbutidos-sim">Sim</label>
                                </div>
                                <div class="form-check form-check-inline main-checkbox">
                                  <input class="form-check-input" type="radio" [formControlName]="'field19'"
                                         id="aviso-desocupacaoarmarios-imbutidos-nao" value="Não">
                                  <span></span>
                                  <label class="form-check-label"
                                         for="aviso-desocupacaoarmarios-imbutidos-nao">Não</label>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="aviso-desocupacaosala-de-jantar-sim" class="col-12">Sala de jantar</label>
                              <div class="col-12">
                                <div class="form-check form-check-inline main-checkbox">
                                  <input class="form-check-input" type="radio" [formControlName]="'field21'"
                                         id="aviso-desocupacaosala-de-jantar-sim" value="Sim">
                                  <span></span>
                                  <label class="form-check-label"
                                         for="aviso-desocupacaosala-de-jantar-sim">Sim</label>
                                </div>
                                <div class="form-check form-check-inline main-checkbox">
                                  <input class="form-check-input" type="radio" [formControlName]="'field21'"
                                         id="aviso-desocupacaosala-de-jantar-nao" value="Não">
                                  <span></span>
                                  <label class="form-check-label"
                                         for="aviso-desocupacaosala-de-jantar-nao">Não</label>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="aviso-desocupacaodependencia-de-empregada-sim" class="col-12">Dependência de
                                empregada</label>
                              <div class="col-12">
                                <div class="form-check form-check-inline main-checkbox">
                                  <input class="form-check-input" type="radio" [formControlName]="'field22'"
                                         id="aviso-desocupacaodependencia-de-empregada-sim" value="Sim">
                                  <span></span>
                                  <label class="form-check-label" for="aviso-desocupacaodependencia-de-empregada-sim">Sim</label>
                                </div>
                                <div class="form-check form-check-inline main-checkbox">
                                  <input class="form-check-input" type="radio" [formControlName]="'field22'"
                                         id="aviso-desocupacaodependencia-de-empregada-nao" value="Não">
                                  <span></span>
                                  <label class="form-check-label" for="aviso-desocupacaodependencia-de-empregada-nao">Não</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="mt-3 mb-3 col-12">TRANSAÇÃO</h6>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaovalor-de-venda">Valor de venda</label>
                          <input type="text" class="form-control js-mask-money" name="valor-de-venda"
                                 [formControlName]="'field23'" [textMask]="{mask: MASKS.currency.textMask}"
                                 id="aviso-desocupacaovalor-de-venda" placeholder="R$ " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaovalor-de-aluguel">Valor de aluguel</label>
                          <input type="text" class="form-control js-mask-money" name="valor-de-aluguel"
                                 [formControlName]="'field24'" [textMask]="{mask: MASKS.currency.textMask}"
                                 id="aviso-desocupacaovalor-de-aluguel" placeholder="R$ " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaovalor-de-condominio">Valor do condomínio</label>
                          <input type="text" class="form-control js-mask-money" name="valor-de-condominio"
                                 [formControlName]="'field25'" [textMask]="{mask: MASKS.currency.textMask}"
                                 id="aviso-desocupacaovalor-de-condominio" placeholder="R$ " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="aviso-desocupacaoiptu-mensal">Valor do IPTU</label>
                          <input type="text" class="form-control js-mask-money" name="iptu-mensal"
                                 [formControlName]="'field26'" [textMask]="{mask: MASKS.currency.textMask}"
                                 id="aviso-desocupacaoiptu-mensal" placeholder="R$ " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="aviso-desocupacaooutras-opcoes">Outras informações</label>
                          <textarea class="form-control" name="outras-opcoes" id="aviso-desocupacaooutras-opcoes"
                                    [formControlName]="'field27'"
                                    placeholder="Opcional"></textarea>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-12 form-group">
                          <label for="aviso-desocupacaoanexo-foto-do-imovel">Anexe uma foto do imóvel</label>
                          <label class="form-input-file">
                            <input type="hidden" name="foto-do-imovel" [formControlName]="'field20'">
                            <input type="file" multiple id="aviso-desocupacaoanexo-foto-do-imovel"
                                   (change)="onFileChange($event, form5filename1, form5.controls.field20)">
                            <div>
                              <span class="form-input-file-span" for="formFile">Escolher arquivos</span>
                              <small #form5filename1></small>
                            </div>
                            <small class="form-input-file-clean"
                                   *ngIf="form5filename1.innerText && form5filename1.innerText !== 'Carregando...'"
                                   (click)="cleanFiles(form5filename1, form5.controls.field20, $event)">
                              <div class="close-me">
                                <img src="../../assets/images/coolicon-grey.svg"/>
                              </div>
                            </small>
                          </label>
                        </div>
                      </div>
                    </fieldset>

                    <div class="col-12 form-group text-center mt-5 mb-3 mx-auto">
                      <button type="submit" class="btn btn-search btn-rounded" (click)="submit(form5, FORM5_ID)">ENVIAR</button>
                    </div>
                  </form>
                  <div class="form-return"></div>
                </div>
              </div>
            </div>
          </div>


          <div class="card">
            <div class="card-header" id="heading8" data-toggle="collapse" data-target="#collapse8"
                 [class.collapsed]="!showForm8" (click)="showForm8 = !showForm8"
                 aria-expanded="false" aria-controls="collapse8">
              <div class="row py-3">
                <div class="col-10"><h2 [class.orange-text]="showForm8">PROPOSTA DE LOCAÇÃO</h2></div>
                <div class="col-2 text-right">
                  <i class="fas pull-right fa-plus-circle" *ngIf="!showForm8"></i>
                  <i class="fas pull-right orange-text fa-minus-circle" *ngIf="showForm8"></i>
                </div>
              </div>
            </div>
            <div id="collapse8" class="collapse" aria-labelledby="heading8" data-parent="#accordionExample"
                 [class.show]="showForm8">
              <div class="card-body">
                <div class="form-card">
                  <div class="col-12 col-lg-10 offset-lg-1 mb-5 pb-2 form-fieldset">
                    <h6>PROPOSTA DE LOCAÇÃO</h6>
                    <p>Preencha e envie o formulário abaixo para realizar a proposta.</p>
                  </div>
                  <form class="col-12 col-lg-10 offset-lg-1 js-form-to-email" [formGroup]="form6"
                        id="proposta-de-locacaoFormSevicos">
                    <h6>DADOS DO PROPONENTE</h6>
                    <p>Essa é uma locação:</p>
                    <fieldset class="form-fieldset">
                      <div class="row">
                        <div class="col-12 col-lg-12 form-group">
                          <div class="form-check form-check-inline main-checkbox">
                            <input class="form-check-input" type="radio" [formControlName]="'radio1'"
                                   id="proposta-de-locacaoresidencial" value="Residencial">
                            <span></span>
                            <label class="form-check-label" for="proposta-de-locacaoresidencial">Residencial</label>
                          </div>
                          <div class="form-check form-check-inline main-checkbox">
                            <input class="form-check-input" type="radio" [formControlName]="'radio1'"
                                   id="proposta-de-locacaonao-fiador" value="Não Residencial">
                            <span></span>
                            <label class="form-check-label" for="proposta-de-locacaonao-fiador">Não
                              Residencial</label>
                          </div>
                        </div>
                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacaonome">Nome</label>
                          <input type="text" class="form-control" placeholder="Nome Completo"
                                 [formControlName]="'field1'"
                                 id="proposta-de-locacaonome" name="nome" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacaoemail">E-mail</label>
                          <input type="email" class="form-control" placeholder="Digite seu e-mail aqui" name="email"
                                 [formControlName]="'field2'"
                                 id="proposta-de-locacaoemail" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacaotelefone">Telefone/celular</label>
                          <input type="text" class="form-control js-mask-phone" name="telefone"
                                 [formControlName]="'field3'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 id="proposta-de-locacaotelefone" placeholder="( ) 00000-0000" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacaorg">RG</label>
                          <input type="text" class="form-control js-mask-rg" name="rg" id="proposta-de-locacaorg"
                                 [formControlName]="'field4'"
                                 placeholder="00000000-0" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacaocpf">CPF</label>
                          <input type="text" class="form-control js-mask-cpf" name="cpf" id="proposta-de-locacaocpf"
                                 [formControlName]="'field5'" [textMask]="{mask: MASKS.cpf.textMask}"
                                 placeholder="000.000.000-00" required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="col-12">GARANTIAS</h6>
                        <div class="col-12">
                          <div class="services-form-line">
                            <div class="col-12 col-lg-12 form-group">
                              <div class="d-inline-flex mr-5 ml-5 mt-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'field6'"
                                       id="proposta-de-locacaopessoa-fisica" value="pessoa-fisica" >
                                <span></span>
                                <label class="form-check-label" for="proposta-de-locacaopessoa-fisica">Pessoa
                                  física</label>
                              </div>
                              <div class="d-inline-flex mr-5 ml-5 mt-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'field7'"
                                       id="proposta-de-locacaoseguro-fianca" value="Seguro fiança" >
                                <span></span>
                                <label class="form-check-label" for="proposta-de-locacaoseguro-fianca">Seguro
                                  fiança</label>
                              </div>
                              <div class="d-inline-flex mr-5 ml-5 mt-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'field8'"
                                       id="proposta-de-locacaotitulo-de-capitalizacao"
                                       value="Título de capitalização">
                                <span></span>
                                <label class="form-check-label" for="proposta-de-locacaotitulo-de-capitalizacao">Título
                                  de capitalização</label>
                              </div>
                              <div class="d-inline-flex mr-5 ml-5 mt-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'field9'"
                                       id="proposta-de-locacaopessoa-juridica" value="Pessoa jurídica">
                                <span></span>
                                <label class="form-check-label" for="proposta-de-locacaopessoa-juridica">Pessoa
                                  jurídica</label>
                              </div>
                              <div class="d-inline-flex mr-5 ml-5 mt-3">
                                <input class="form-check-input" type="checkbox" [formControlName]="'field10'"
                                       id="proposta-de-locacaocaucao" value="Caução">
                                <span></span>
                                <label class="form-check-label" for="proposta-de-locacaocaucao">Caução</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row justify-content-center">
                        <h6 class="col-12">PROPOSTA</h6>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacaocod-imovel">Código do imóvel</label>
                          <input type="text" class="form-control" placeholder=" " id="proposta-de-locacaocod-imovel"
                                 [formControlName]="'field11'"
                                 name="cod-imovel" required>
                          <span></span>
                        </div>

                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="end-do-imovel">Endereço do imóvel</label>
                          <input type="text" class="form-control" name="end-do-imovel"
                                 [formControlName]="'field15'"
                                 id="end-do-imovel" required>
                          <span></span>
                        </div>

                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="end-do-imovel-num">Número do apartamento</label>
                          <input type="text" class="form-control" name="end-do-imovel-num"
                                 [formControlName]="'field16'"
                                 id="end-do-imovel-num">
                          <span></span>
                        </div>

                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="end-do-imovel-torre">Torre</label>
                          <input type="text" class="form-control" name="end-do-imovel-torre"
                                 [formControlName]="'field17'"
                                 id="end-do-imovel-torre">
                          <span></span>
                        </div>

                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="end-do-imovel-condominio">Nome do condomínio</label>
                          <input type="text" class="form-control" name="end-do-imovel-condominio"
                                 [formControlName]="'field15'"
                                 id="end-do-imovel-condominio">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacaovalor-da-locacao">Valor da locação</label>
                          <input type="text" class="form-control" placeholder="R$ " name="valor-da-locacao"
                                 [formControlName]="'field12'"
                                 id="proposta-de-locacaovalor-da-locacao" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacaoprazo-do-contrato">Prazo do contrato</label>
                          <input type="text" class="form-control js-mask-date" name="prazo-do-contrato"
                                 [formControlName]="'field13'"
                                 id="proposta-de-locacaoprazo-do-contrato" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacaofinalidade-da-locacao">Finalidade da locação</label>
                          <input type="text" class="form-control" name="finalidade-da-locacao"
                                 [formControlName]="'field14'"
                                 id="proposta-de-locacaofinalidade-da-locacao" placeholder=" " required>
                          <span></span>
                        </div>

                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-corretor">Corretor</label>
                          <select class="form-control field-corretor clickable" name="estado-civil" id="rand2-corretor"
                                  required
                                  [formControlName]="'field19'">
                            <span></span>
                            <option value=""></option>
                            <option value="{{corretor.email_do_corretor}}"
                                    *ngFor="let corretor of corretores">{{corretor.nome_do_corretor}}</option>
                          </select>
                        </div>

                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="proposta-de-locacao-obs">Observação</label>
                          <textarea class="form-control" [formControlName]="'field20'"
                                    id="proposta-de-locacao-obs" placeholder="Opcional"></textarea>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <div class="col-12 form-group text-center mt-5 mb-3 mx-auto">
                      <button type="submit" class="btn btn-search btn-rounded" (click)="submit(form6, FORM6_ID)">ENVIAR</button>
                    </div>
                  </form>
                  <div class="form-return"></div>
                </div>
              </div>
            </div>
          </div>


          <div class="card">
            <div class="card-header" id="heading9" data-toggle="collapse" data-target="#collapse9"
                 [class.collapsed]="!showForm9" (click)="showForm9 = !showForm9"
                 aria-expanded="false" aria-controls="collapse9">
              <div class="row py-3">
                <div class="col-10"><h2 [class.orange-text]="showForm9">PROPOSTA DE COMPRA</h2></div>
                <div class="col-2 text-right">
                  <i class="fas pull-right fa-plus-circle" *ngIf="!showForm9"></i>
                  <i class="fas pull-right orange-text fa-minus-circle" *ngIf="showForm9"></i>
                </div>
              </div>
            </div>
            <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordionExample"
                 [class.show]="showForm9">
              <div class="card-body">
                <div class="form-card">
                  <div class="col-12 col-lg-10 offset-lg-1 mb-5 pb-2 form-fieldset">
                    <h6>PROPOSTA DE COMPRA</h6>
                    <p>Preencha e envie o formulário abaixo para realizar a proposta.</p>
                  </div>
                  <form class="col-12 col-lg-10 offset-lg-1 js-form-to-email" [formGroup]="form7"
                        id="rand2-FormSevicos">
                    <h6>DADOS DO PROPONENTE</h6>
                    <fieldset class="form-fieldset">
                      <div class="row">
                        <div class="col-12 col-lg-12 form-group">
                          <label for="rand2-razao-social">Razão Social</label>
                          <input type="text" class="form-control" placeholder=" " id="rand2-razao-social"
                                 [formControlName]="'field1'"
                                 name="razao-social">
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="rand2-cnpj">CNPJ</label>
                          <input type="text" class="form-control js-mask-cnpj" name="cnpj" id="rand2-cnpj"
                                 [formControlName]="'field2'" [textMask]="{mask: MASKS.cnpj.textMask}"
                                 placeholder="00.000.000/0000-00 " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="rand2-ie">IE</label>
                          <input type="text" class="form-control" name="ie" id="rand2-ie" [formControlName]="'field3'"
                                 placeholder="Número de inscrição estadual" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="rand2-ramo-de-atividade">Ramo de atividade</label>
                          <input type="text" class="form-control" name="ramo-de-atividade" [formControlName]="'field4'"
                                 id="rand2-ramo-de-atividade" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="rand2-inicio-das-atividades">Inicio das atividades</label>
                          <input type="text" class="form-control js-mask-date" name="inicio-das-atividades"
                                 [formControlName]="'field5'" [textMask]="{mask: MASKS.data.textMask}"
                                 id="rand2-inicio-das-atividades" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="rand2-email">E-mail</label>
                          <input type="email" class="form-control" placeholder="Digite seu e-mail aqui" name="email"
                                 [formControlName]="'field6'"
                                 id="rand2-email" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group">
                          <label for="rand2-telefone">Telefone/celular</label>
                          <input type="text" class="form-control js-mask-phone" name="telefone" id="rand2-telefone"
                                 [formControlName]="'field7'" [textMask]="{mask: MASKS.telefone.textMaskFunction}"
                                 placeholder="( ) 00000-0000" required>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset class="form-fieldset">
                      <div class="row">
                        <h6 class="col-12">PROPOSTA</h6>
                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-cod-imovel">Código do imóvel</label>
                          <input type="text" class="form-control" placeholder=" " id="rand2-cod-imovel"
                                 [formControlName]="'field8'"
                                 name="cod-imovel" required>
                          <span></span>
                        </div>
                        <!--                        Endereço do imóvel-->
                        <!--                        Número do apartamento-->
                        <!--                        Torre-->
                        <!--                        Nome do condomínio-->
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-end-do-imovel">Endereço do imóvel</label>
                          <input type="text" class="form-control" name="rand2-end-do-imovel"
                                 [formControlName]="'field15'"
                                 id="rand2-end-do-imovel" required>
                          <span></span>
                        </div>

                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-end-do-imovel-num">Número do apartamento</label>
                          <input type="text" class="form-control" name="rand2-end-do-imovel-num"
                                 [formControlName]="'field16'"
                                 id="rand2-end-do-imovel-num">
                          <span></span>
                        </div>

                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-end-do-imovel-torre">Torre</label>
                          <input type="text" class="form-control" name="rand2-end-do-imovel-torre"
                                 [formControlName]="'field17'"
                                 id="rand2-end-do-imovel-torre">
                          <span></span>
                        </div>

                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-end-do-imovel-condominio">Nome do condomínio</label>
                          <input type="text" class="form-control" name="rand2-end-do-imovel-condominio"
                                 [formControlName]="'field15'"
                                 id="rand2-end-do-imovel-condominio">
                          <span></span>
                        </div>

                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-valor-da-locacao">Valor da locação</label>
                          <input type="text" class="form-control" placeholder="R$ " name="valor-da-locacao"
                                 [formControlName]="'field9'"
                                 id="rand2-valor-da-locacao" required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-prazo-do-contrato">Prazo do contrato</label>
                          <input type="text" class="form-control js-mask-date" name="prazo-do-contrato"
                                 [formControlName]="'field10'"
                                 id="rand2-prazo-do-contrato" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-data-do-inicio">Data do inicio</label>
                          <input type="text" class="form-control js-mask-date" name="data-do-inicio"
                                 [formControlName]="'field11'" [textMask]="{mask: MASKS.data.textMask}"
                                 id="rand2-data-do-inicio" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-vencimento-todo-dia">Vencimento todo dia</label>
                          <input type="text" class="form-control js-mask-date" name="vencimento-todo-dia"
                                 [formControlName]="'field12'"
                                 id="rand2-vencimento-todo-dia" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-finalidade-da-locacao">Finalidade da locação</label>
                          <input type="text" class="form-control js-mask-date" name="finalidade-da-locacao"
                                 [formControlName]="'field13'"
                                 id="rand2-finalidade-da-locacao" placeholder=" " required>
                          <span></span>
                        </div>
                        <div class="col-12 col-lg-6 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-corrretor">Corretor</label>
                          <select class="form-control field-corretor clickable" name="estado-civil" id="rand2-corrretor"
                                  required
                                  [formControlName]="'field14'">
                            <span></span>
                            <option value=""></option>
                            <option value="{{corretor.email_do_corretor}}"
                                    *ngFor="let corretor of corretores">{{corretor.nome_do_corretor}}</option>
                          </select>
                        </div>
                        <div class="col-12 col-lg-12 form-group d-flex flex-column justify-content-end">
                          <label for="rand2-obs">Observação</label>
                          <textarea class="form-control" [formControlName]="'field19'"
                                    id="rand2-obs" placeholder="Opcional"></textarea>
                          <span></span>
                        </div>
                      </div>
                    </fieldset>


                    <div class="col-12 form-group text-center mt-5 mb-3 mx-auto">
                      <button type="submit" class="btn btn-search btn-rounded"
                              (click)="submit(form7, FORM7_ID)">ENVIAR
                      </button>
                    </div>
                  </form>
                  <div class="form-return"></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <ng-template #content2 let-modal>
    <div class="modal-content">
      <div class="card text-center">
        <div class="text-dark-gray bg-gray-100 text-uppercase py-3">
          <button type="button" class="close text-right pr-4" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img src="assets/img/icons/icon-times.svg" width="20"></span>
          </button>
        </div>
        <div class="card-body bg-gray-100 p-5">
          <p class="col-8 offset-2 text-uppercase">COMO LOCALIZAR SEU NÚMERO DE CONTRATO.</p>
          <p class="col-8 offset-2 text-center resetpassword-text d-block">O código para gerar a 2ª via do seu
            boleto está disponível em três lugares, como mostram as marcações laranjas das imagens abaixo: </p>

          <div class="row align-content-center">

            <div class="col-2 text-left">
              <a (click)="imgBoleto === 3 ?  imgBoleto = 1 : imgBoleto = imgBoleto + 1" class="arrow-left-blog">
                <img src="../assets/img/propertyLeftArrow.png" alt="">
              </a>
            </div>
            <div class="col">
              <div class="FormStyle owl-carousel owl-theme boleto-modal">
                <div class="item" *ngIf="imgBoleto === 1">
                  <p> O número do seu contrato pode ser encontrado no Recibo. </p>
                  <img src="assets/img/recibo.png" alt="" title="" class="img-fluid">
                </div>
                <div class="item" *ngIf="imgBoleto === 2">
                  <p> O número do seu contrato pode ser encontrado no Boleto bancário.</p>
                  <img src="assets/img/boleto.png" alt="" title="" class="img-fluid">
                </div>
                <div class="item" *ngIf="imgBoleto === 3">
                  <p> O número do seu contrato pode ser encontrado no Contrato de locação. </p>
                  <img src="assets/img/contrato.png" alt="" title="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="col-2 text-right">
              <a (click)="imgBoleto === 1 ?  imgBoleto = 3 : imgBoleto = imgBoleto - 1" class="arrow-right-blog">
                <img src="../assets/img/propertyRightArrow.png" alt="">
              </a>
            </div>


          </div>

          <div class="col-12 text-center my-4">
            <button class="btn btn-login btn-rounded mt-3" (click)="modal.dismiss('Cross click')" type="button">
              Ok
            </button>
          </div>

        </div>
      </div>


    </div>
  </ng-template>


  <ng-template #content let-modal>
    <div class="modal-content">
      <div class="card text-center">
        <div class="card-header text-dark-gray bg-gray-100 font-weight-bolder text-uppercase py-3">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="card-body text-left p-5">
          <h6>
            <svg xmlns="http://www.w3.org/2000/svg" width="28.531" height="21.488" viewBox="0 0 28.531 21.488">
              <path id="Caminho_361" data-name="Caminho 361"
                    d="M28.183,2.742,25.883.3A1.128,1.128,0,0,0,24.3.3L10.647,13.953,4.323,7.485a1.128,1.128,0,0,0-1.581,0L.3,9.928a1.128,1.128,0,0,0,0,1.581l9.486,9.63a1.128,1.128,0,0,0,1.581,0L28.183,4.323A1.128,1.128,0,0,0,28.183,2.742Z"
                    transform="translate(0.025 0.025)" fill="#fe6900"/>
            </svg>
            <span class="s">Dados enviados com sucesso!</span>
          </h6>
          <p class="js-services-modal-text">Obrigada! Já recebemos seus dados e em breve entraremos em contato para
            marcarmos uma conversa.</p>
          <div class="col-12 text-center my-4">
            <button class="btn btn-rounded mt-3 btn-login btn-login-send" data-dismiss="modal">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
