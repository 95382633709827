<footer>
  <div class="footer">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-auto">
          <img src="assets/img/logo-next-header.svg" alt="" class="logo-rodape">
        </div>
        <div class="col-auto">
          <div class="row">
            <div class="col-12 col-sm-auto footer-col">
              <ul class="footer-links">
                <li class="title">Sobre nós</li>
                <li><a [routerLink]="['/sobre-nos/']">Nossa História</a></li>
                <li><a [routerLink]="['/quero-negociar/']">Como Fazemos</a></li>
              </ul>
              <ul class="footer-links">
                <li class="title"><a href="https://api.whatsapp.com/send?phone=5519994760293&text=Oi,%20gostaria%20de%20saber%20sobre%20vagas%20na%20Next!" target="_blank">TRABALHE CONOSCO</a></li>
              </ul>
              <ul class="footer-links">
                <li class="title"><a [routerLink]="['/blog/']">Blog Next</a></li>
              </ul>
              <ul class="footer-links">
                <li class="title"><a [routerLink]="['/servicos/']">Serviços e Formulários</a></li>
              </ul>
              <ul class="footer-links">
<!--                <li class="title"><a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/next-jsonserver.appspot.com/o/lgpd%2FPoli%CC%81tica%20de%20Privacidade.pdf?alt=media&token=a5f8037d-fa3f-4f32-a0e5-d1f24ecc1a87">Política de Cookies</a></li>-->
                <li class="title"><a [routerLink]="['/politica-de-cookies/']" >Política de Cookies</a></li>
              </ul>
            </div>
            <div class="col-12 col-sm-auto footer-col">
              <ul class="footer-links">
                <li class="title">Quero Alugar</li>
                <li><a [routerLink]="['/imoveis/']" [queryParams]="{finalidade: 'residencial', categoria: 'alugar'}">Imóveis
                  Residenciais</a></li>
                <li><a [routerLink]="['/imoveis/']" [queryParams]="{finalidade: 'comercial', categoria: 'alugar'}">Imóveis
                  Comerciais</a></li>
              </ul>
              <ul class="footer-links">
                <li class="title">Quero Comprar</li>
                <li><a [routerLink]="['/imoveis/']" [queryParams]="{finalidade: 'residencial', categoria: 'comprar'}">Imóveis
                  Residenciais</a></li>
                <li><a [routerLink]="['/imoveis/']" [queryParams]="{finalidade: 'comercial', categoria: 'comprar'}">Imóveis
                  Comerciais</a></li>
              </ul>
              <ul class="footer-links">
                <li class="title"><a [routerLink]="['/quero-negociar/']">Quero anunciar</a></li>
              </ul>
            </div>
            <div class="col-12 col-sm-auto footer-col">
              <!--          <ul class="footer-links d-none d-sm-none d-md-none d-lg-block">-->
              <!--            <li class="title">Contato</li>-->
              <!--            <li><a href="fale-conosco.html">Fale Conosco</a></li>-->
              <!--            &lt;!&ndash; <li><a href="">Trabalhe na Next</a></li> &ndash;&gt;-->
              <!--          </ul>-->
              <ul class="footer-links">
                <li class="title">Telefone</li>
                <li class="numtel"><img class="social" src="assets/img/tel-icon.svg" alt=""> <a
                  href="callto:+55192512-0000">2512-0000</a></li>
              </ul>
              <ul class="footer-links" style="margin-bottom: 30px;">
                <li class="title">Whatsapp</li>
                <li class="numtel"><img src="assets/img/zap-icon.svg" alt=""> <a
                  href="https://api.whatsapp.com/send?phone=5519999120030">(19) 99912-0030</a></li>
              </ul>
              <ul class="footer-links">
                <li class="title">Redes Sociais</li>
                <ul class="list-inline">
                  <li class="list-inline-item px-md-0 px-lg-1 "><a href="https://www.facebook.com/nextsim" target="_blank"
                                                                   data-toggle="tooltip" data-placement="bottom"
                                                                   title="Facebook">
                    <img class="social-media-img"
                         src="assets/img/Caminho&#32;354.png"
                         alt=""></a>
                  </li>
                  <li class="list-inline-item px-md-0 px-lg-1 instagram"><a href="https://www.instagram.com/next_sim/"
                                                                            data-toggle="tooltip"
                                                                            target="_blank"
                                                                            data-placement="bottom"
                                                                            title="Instagram"><img
                    class="social-media-img"
                    src="assets/img/Caminho&#32;355.png"
                    alt=""></a></li>
                  <li class="list-inline-item px-md-0 px-lg-1 yt">
                    <a href="https://www.youtube.com/@next_sim"
                    data-toggle="tooltip"
                       target="_blank"
                    data-placement="bottom"
                    title="YouTube">
                      <img
                    class="social-media-img"
                    src="assets/img/yt.png"
                    alt=""></a></li>

                  <li class="list-inline-item px-md-0 px-lg-1 yt">
                    <a href="https://www.linkedin.com/company/next-sim"
                       data-toggle="tooltip"
                       data-placement="bottom"
                       target="_blank"
                       title="LinkedIn">
                      <img
                        class="social-media-img"
                        src="assets/img/in.png"
                        alt=""></a></li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bar">
    <div class="container">
      <p class="creditos">Next soluçōes imobiliárias - Av. Dr. Jesuíno Marcondes Machado, 440 - Nova Campinas, Campinas
        - SP, 13092-108 <span
          class="breakcnpj"><br>CNPJ 03.492.692/0001-20 | CRECI: 17.452 J </span></p>
    </div>
  </div>
</footer>
