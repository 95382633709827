import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IOSDetectionService {

  constructor() { }
  isIOS(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
}
