<div id="backdrop">
  <section class="content">
    <div class="container bg-gray">
      <div class="row py-5">
        <div class="col-12">
          <p><b><h2>Pol&iacute;tica de privacidade</h2></b><br />
            Nas intera&ccedil;&otilde;es com a Next Solu&ccedil;&otilde;es Imobili&aacute;rias, voc&ecirc; nos fornece seus dados pessoais para que possamos atend&ecirc;-lo e conect&aacute;-lo ao ecossistema Next de servi&ccedil;os. Na Next, privacidade, seguran&ccedil;a e transpar&ecirc;ncia s&atilde;o valores fundamentais, portanto, a Next sempre adotou e, a cada dia, procura sempre evoluir nas melhores pr&aacute;ticas de governan&ccedil;a para garantir a confidencialidade, integridade e o tratamento adequado dos seus dados pessoais. Entenda aqui como tratamos desses dados.<br /><br />
            <b>1. Dados coletados</b><br /><br />
            Na miss&atilde;o da Next Solu&ccedil;&otilde;es Imobili&aacute;rias de ajudar cada pessoa a encontrar o seu lugar, coletamos os seguintes dados:<br /><br />
            &#9679; Dados pessoais fornecidos pelo titular: dados inseridos ou encaminhados no decorrer do acesso aos nossos canais ou na contrata&ccedil;&atilde;o de produtos e servi&ccedil;os pelo pr&oacute;prio titular dos dados;<br />
            &#9679; Dados pessoais fornecidos via terceiros: a Next Solu&ccedil;&otilde;es Imobili&aacute;rias pode receber seus dados por interm&eacute;dio de parceiros ou prestadores de servi&ccedil;o que tenham algum relacionamento com o titular; (ii) dados coletados diretamente de bases p&uacute;blicas ou publicamente dispon&iacute;veis; ou (iii) dados que foram tornados p&uacute;blicos pelo titular em sites ou redes sociais.<br />
            &#9679; Dados pessoais coletados eletronicamente: em nossos portais tamb&eacute;m existe a possibilidade de coleta de dados pessoais autom&aacute;tica, por meio de cookies, com o prop&oacute;sito de fazer com que a experi&ecirc;ncia de navega&ccedil;&atilde;o do usu&aacute;rio seja a melhor poss&iacute;vel<br /><br />
            <b>2. Uso dos dados pessoais</b><br/><br />
            A tabela abaixo indica a finalidade de uso dos dados pessoais coletados: Dado Pessoal Finalidade Dados pessoais informados no cadastro, como nome e sobrenome, e telefone, e-mail.<br /><br />
            &#9679; Identificar o titular dos dados em nossas plataformas e em todo o ecossistema Next Solu&ccedil;&otilde;es Imobili&aacute;rias;<br />
            &#9679; Realizar o cadastro do titular;<br />
            &#9679; Entrar em contato com o titular;<br />
            &#9679; Manter dados de contato atualizados para fins comerciais;<br />
            &#9679; Atender solicita&ccedil;&otilde;es e d&uacute;vidas em nossos canais de atendimento;<br />
            &#9679; Informar a respeito de novidades e produtos/servi&ccedil;os que possam ser do interesse do titular;<br />
            &#9679; Informar a respeito de mudan&ccedil;as em nossas pol&iacute;ticas;<br /><br />
            Dados pessoais obtidos na navega&ccedil;&atilde;o (cookies), como registro de IP, data e hora de acesso, localiza&ccedil;&atilde;o geogr&aacute;fica.<br /><br />
            &#9679; Melhorar a experi&ecirc;ncia do cliente em toda a sua jornada com a Next, seja de forma off-line ou por meios eletr&ocirc;nicos, tais como portais e plataformas gerenciadas pela Next Solu&ccedil;&otilde;es Imobili&aacute;rias;<br />
            &#9679; Efetuar levantamentos estat&iacute;sticos para oferecer servi&ccedil;os personalizados;<br />
            &#9679; Direcionar publicidade tanto nas nossas plataformas como em sites de terceiros;<br />
            &#9679; Customizar conte&uacute;do e publicidade que aparecem em nossas plataformas;<br />
            &#9679; Recomendar produtos ou servi&ccedil;os, nossos ou de terceiros, que possam ser do seu interesse;<br />
            &#9679; Monitorar atividades e tend&ecirc;ncias de uso;<br />
            &#9679; Mensurar intera&ccedil;&otilde;es e audi&ecirc;ncia dos servi&ccedil;os;<br />
            &#9679; Cumprir obriga&ccedil;&otilde;es regulat&oacute;rias e legais;<br /><br />
            Dados pessoais recebidos de terceiros como nome e telefone enviado por portais de im&oacute;veis em que voc&ecirc; se cadastra e demonstra interesse em im&oacute;veis da nossa carteira.<br /><br />
            &#9679; Garantir a sua seguran&ccedil;a na presta&ccedil;&atilde;o de nossos servi&ccedil;os e preven&ccedil;&atilde;o de fraudes;<br />
            &#9679; Agilizar processos de cadastro e melhorar a sua experi&ecirc;ncia em nossas plataformas;<br />
            &#9679; Prevenir problemas t&eacute;cnicos e de seguran&ccedil;a, como invas&otilde;es e incidentes de seguran&ccedil;a;<br />
            &#9679; Entrar em contato com o titular.<br /><br />
            <b>2.1. Cookies</b><br/><br />
            Os cookies coletam algumas informa&ccedil;&otilde;es de navega&ccedil;&atilde;o na web. Quando uma pessoa acessa um site na web, o servidor da p&aacute;gina web (site) envia um pequeno fluxo de informa&ccedil;&otilde;es ao navegador que ficam armazenadas no servidor do site na forma conhecida como &ldquo;cookies&rdquo;, e esses cookies s&atilde;o ativados sempre que ocorrer uma nova visita do usu&aacute;rio ao mesmo site.<br />
            As informa&ccedil;&otilde;es coletadas nos arquivos s&atilde;o interpretadas e executadas, o que possibilita a identifica&ccedil;&atilde;o dos interesses e necessidades da pessoa que acessa a p&aacute;gina, fazendo com que o site seja exibido de forma personalizada. Essa intera&ccedil;&atilde;o faz com que a experi&ecirc;ncia do usu&aacute;rio seja mais r&aacute;pida e fluida. Os cookies utilizados em nossos portais t&ecirc;m as seguintes finalidades:<br /><br />
            &#9679; Cookies essenciais: s&atilde;o cookies necess&aacute;rios para o funcionamento das p&aacute;ginas Next Solu&ccedil;&otilde;es Imobili&aacute;rias na web e para possibilitar o cumprimento de obriga&ccedil;&otilde;es legais. Sem estes cookies, nosso site ir&aacute; operar sem todas as suas funcionalidades, e podemos n&atilde;o ser capazes de fornecer uma experi&ecirc;ncia satisfat&oacute;ria dos servi&ccedil;os ou recurso.<br />
            &#9679; Cookies de Prefer&ecirc;ncia: coletam informa&ccedil;&otilde;es sobre prefer&ecirc;ncias do usu&aacute;rio, permitindo facilitar sua navega&ccedil;&atilde;o por meio do armazenamento das escolhas (como idioma ou localiza&ccedil;&atilde;o). Esses cookies de prefer&ecirc;ncia registram informa&ccedil;&otilde;es espec&iacute;ficas de navega&ccedil;&atilde;o (incluindo a maneira como o usu&aacute;rio chega ao site, as p&aacute;ginas visualizadas, as op&ccedil;&otilde;es selecionadas, as informa&ccedil;&otilde;es inseridas e o caminho percorrido no site).<br />
            &#9679; Cookies de redes sociais: s&atilde;o cookies de redes sociais que t&ecirc;m por finalidade apresentar an&uacute;ncios mais relevantes e permitir avaliar a sua intera&ccedil;&atilde;o com nosso conte&uacute;do.<br />
            &#9679; Cookies Anal&iacute;ticos: os cookies anal&iacute;ticos coletam informa&ccedil;&otilde;es sobre a utiliza&ccedil;&atilde;o do site, permitindo que a gente melhore o seu funcionamento. Por exemplo, os cookies anal&iacute;ticos identificam quais s&atilde;o as p&aacute;ginas mais visitadas, registram quaisquer dificuldades que os usu&aacute;rios sintam na navega&ccedil;&atilde;o, e identificam se a nossa publicidade &eacute; eficaz.<br />
            &#9679; Voc&ecirc; pode configurar o uso de cookies diretamente no seu navegador. Para cada navegador utilizado, existem procedimentos definidos pelos seus desenvolvedores, conforme links abaixo:<br />
            &#9679; Google/Chrome:https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=pt-BR<br />
            &#9679; Internet Explorer:https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies<br />
            &#9679; Mozilla Firefox:https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam<br />
            &#9679; Safari:https://support.apple.com/pt-br/guide/safari/sfri11471/mac<br /><br /><br />
            <b>3. Com quem compartilhamos</b><br/><br />
            Haver&aacute; casos em que precisamos compartilhar os dados pessoais dos titulares com terceiros, tais como:<br /><br />
            &#9679; Fornecedores. O ecossistema de servi&ccedil;os Next Solu&ccedil;&otilde;es Imobili&aacute;rias conta com servi&ccedil;os e produtos de terceiros, que podem ter acesso aos dados pessoais que coletamos. As informa&ccedil;&otilde;es s&atilde;o compartilhadas estritamente para o exerc&iacute;cio de nossas atividades comerciais. Para tanto, avaliamos cuidadosamente nossos fornecedores, que devem garantir a capacidade de se obrigarem &agrave; prote&ccedil;&atilde;o de dados pessoais por n&oacute;s compartilhados, e contar com ferramentas de seguran&ccedil;a da informa&ccedil;&atilde;o adequadas a minorar riscos de vazamentos de informa&ccedil;&otilde;es ou ataques de hackers. Servidores de hospedagem s&atilde;o um exemplo de fornecedor necess&aacute;rio ao nosso exerc&iacute;cio profissional.<br />
            &#9679; Parceiros Comerciais. A intermedia&ccedil;&atilde;o imobili&aacute;ria, por sua natureza e defini&ccedil;&atilde;o legal, ocorre atrav&eacute;s de media&ccedil;&atilde;o de neg&oacute;cios entre diversos interessados, tais como, propriet&aacute;rios, incorporadoras e corretores de im&oacute;veis independentes que se associam &agrave; Next Solu&ccedil;&otilde;es Imobili&aacute;rias para atender seus clientes. Portanto, dados pessoais podem ser compartilhados com esses parceiros &uacute;nica e exclusivamente com o objetivo de alcan&ccedil;ar o neg&oacute;cio imobili&aacute;rio no qual o cliente/usu&aacute;rio Next Solu&ccedil;&otilde;es Imobili&aacute;rias tiver potencial interesse. Da mesma forma como fazemos com nossos fornecedores, empregamos os melhores esfor&ccedil;os para avaliar cuidadosamente se nossos parceiros tratam os dados pessoais em linha com o padr&atilde;o de qualidade que exigimos. Em nenhuma hip&oacute;tese, dados pessoais s&atilde;o compartilhados pela Next Solu&ccedil;&otilde;es Imobili&aacute;rias para finalidades diversas da realiza&ccedil;&atilde;o de intermedia&ccedil;&atilde;o imobili&aacute;ria ou a contrata&ccedil;&atilde;o de produtos e servi&ccedil;os financeiros imobili&aacute;rias, tais como financiamento imobili&aacute;rio ou cons&oacute;rcio imobili&aacute;rio. N&atilde;o h&aacute; hip&oacute;tese de a Next Solu&ccedil;&otilde;es Imobili&aacute;rias compartilhar dados com outras ind&uacute;strias de mercado imobili&aacute;rio acess&oacute;rio, tais como lojas de m&oacute;veis planejados.<br />
            &#9679; Obriga&ccedil;&otilde;es legais. A Next Solu&ccedil;&otilde;es Imobili&aacute;rias ressalva ter o direito de compartilhar quaisquer dados pessoais necess&aacute;rios para cumprir uma obriga&ccedil;&atilde;o legal ou, ainda, proteger os nossos direitos e dos nossos colaboradores.<br /><br />
            <b>4. Como mantemos dados pessoais seguros</b><br /><br />
            Os dados pessoais em posse da Next Solu&ccedil;&otilde;es Imobili&aacute;rias s&atilde;o armazenados de acordo com procedimentos e ferramentas de seguran&ccedil;a da informa&ccedil;&atilde;o off-line ou online. Implementamos:<br /><br />
            &#9679; Controle de guarda dos dados, de forma a assegurar contra acesso n&atilde;o autorizado;<br />
            &#9679; Acesso restrito de pessoas ao local onde s&atilde;o armazenadas as informa&ccedil;&otilde;es pessoais; e<br />
            &#9679; Ado&ccedil;&atilde;o de procedimentos para que fornecedores, parceiros comerciais e colaboradores mantenham dados pessoais<br />
            coletados pela Next Solu&ccedil;&otilde;es Imobili&aacute;rias &ndash; na medida estritamente necess&aacute;ria &ndash; ao desenvolvimento das solu&ccedil;&otilde;es de servi&ccedil;os imobili&aacute;rios que a Next Solu&ccedil;&otilde;es Imobili&aacute;rias coloca &agrave; disposi&ccedil;&atilde;o de seus clientes, e desde que estes tenham condi&ccedil;&otilde;es de manter o tratamento de dados nos limites legais.<br /><br />
            Importante salientar que nenhuma transmiss&atilde;o de informa&ccedil;&otilde;es &eacute; totalmente segura, pois sempre estar&aacute; suscet&iacute;vel &agrave; ocorr&ecirc;ncia de eventos adversos, como falhas t&eacute;cnicas ou ataques digitais. Por isso, a Next Solu&ccedil;&otilde;es Imobili&aacute;rias adota as melhores pr&aacute;ticas ao seu alcance e atua com controles robustos para preservar a privacidade e proteger os dados pessoais de seus clientes ou usu&aacute;rios dos meios digitais Next Solu&ccedil;&otilde;es Imobili&aacute;rias.<br />
            Na hip&oacute;tese de incid&ecirc;ncia de eventos adversos, a Next Solu&ccedil;&otilde;es Imobili&aacute;rias trabalhar&aacute; para remediar as consequ&ecirc;ncias do evento, sempre garantindo a devida transpar&ecirc;ncia. Caso voc&ecirc; tome conhecimento de algo que comprometa a seguran&ccedil;a dos seus dados, por favor, entre em contato conosco pelo canal de atendimento indicado ao final desta pol&iacute;tica.</p><br />

          <p><b>5. Direitos do Titular</b><br/><br />
            O cliente/usu&aacute;rio Next Solu&ccedil;&otilde;es Imobili&aacute;rias tem salvaguardados direitos relativos aos dados pessoais que coletamos e tratamos, atrav&eacute;s dos mecanismos abaixo:<br /><br />
            Confirma&ccedil;&atilde;o e Acesso<br />
            Permite que voc&ecirc; possa verificar se tratamos dados pessoais seus e, em caso positivo, requisitar quais os dados pessoais que n&oacute;s temos sobre voc&ecirc;.<br /><br />
            Corre&ccedil;&atilde;o<br />
            Permite que voc&ecirc; corrija os seus dados pessoais incompletos, inexatos ou desatualizados.<br /><br />
            Bloqueio ou Elimina&ccedil;&atilde;o<br />
            Permite que voc&ecirc; nos pe&ccedil;a para (a) bloquear seus dados, suspendendo, temporariamente, a sua possibilidade de tratamento pela Next Solu&ccedil;&otilde;es Imobili&aacute;rias; e (b) eliminar seus dados, caso em que apagaremos todos os seus dados sem possibilidade de revers&atilde;o, excetos se necess&aacute;rios ao exerc&iacute;cio legal das nossas atividades ou para salvaguardar direitos da Next Solu&ccedil;&otilde;es Imobili&aacute;rias e de seus colaboradores ou demais casos previstos em lei.<br /><br />
            Portabilidade<br />
            Voc&ecirc; tem o direito de solicitar, mediante requisi&ccedil;&atilde;o expressa, que seja fornecido a voc&ecirc;, ou a terceiro que voc&ecirc; escolher, os seus dados pessoais em formato estruturado para transfer&ecirc;ncia a outra empresa, desde que n&atilde;o viole a propriedade intelectual ou segredo de neg&oacute;cios da Next Solu&ccedil;&otilde;es Imobili&aacute;rias.<br /><br />
            Informa&ccedil;&atilde;o sobre o compartilhamento<br />
            Voc&ecirc; tem o direito de saber quem s&atilde;o as entidades p&uacute;blicas e privadas com as quais realizamos uso compartilhado de dados. Se voc&ecirc; tiver d&uacute;vidas ou quiser maiores detalhes, voc&ecirc; tem o direito de nos solicitar essas informa&ccedil;&otilde;es.<br /><br />
            Informa&ccedil;&atilde;o sobre a possibilidade de n&atilde;o consentir<br />
            Permite que voc&ecirc; tenha informa&ccedil;&otilde;es claras e completas sobre a possibilidade e as consequ&ecirc;ncias de n&atilde;o fornecer consentimento. O seu consentimento, quando necess&aacute;rio, deve ser livre e informado. Portanto, sempre que pedirmos seu consentimento, voc&ecirc; poder&aacute; neg&aacute;-lo &ndash; ainda que, nesses casos, &eacute; poss&iacute;vel que tenhamos que limitar alguns servi&ccedil;os.<br /><br />
            Revoga&ccedil;&atilde;o do consentimento<br />
            Voc&ecirc; tem o direito de retirar a permiss&atilde;o de uso de dados pessoais em rela&ccedil;&atilde;o &agrave;s atividades que dependem do seu consentimento. Se voc&ecirc; retirar o seu consentimento, talvez n&atilde;o possamos mais fornecer determinadas comunica&ccedil;&otilde;es ou servi&ccedil;os Next Solu&ccedil;&otilde;es Imobili&aacute;rias para voc&ecirc;.<br /><br />
            Exerc&iacute;cio regular de Direito da Next Solu&ccedil;&otilde;es Imobili&aacute;rias x Oposi&ccedil;&atilde;o do Usu&aacute;rio<br />
            A lei autoriza o tratamento de dados pessoais mesmo sem o seu consentimento. Caso voc&ecirc; n&atilde;o concorde com esse tratamento, em alguns casos, voc&ecirc; poder&aacute; se opor a ele, solicitando a interrup&ccedil;&atilde;o de forma justificada. Caso voc&ecirc; n&atilde;o queira mais receber nossas comunica&ccedil;&otilde;es de marketing, basta clicar no link desinscrever no rodap&eacute; da mensagem recebida.<br /><br />
            <br /><b>6. Reten&ccedil;&atilde;o dos dados pessoais</b><br /><br />
            A Next Solu&ccedil;&otilde;es Imobili&aacute;rias est&aacute; comprometida com a manuten&ccedil;&atilde;o das suas informa&ccedil;&otilde;es de forma segura e apenas pelo per&iacute;odo necess&aacute;rio. Os dados pessoais, tratados pela companhia, ser&atilde;o eliminados quando n&atilde;o forem mais necess&aacute;rios aos fins para os quais foram coletados, ou quando solicitado pelo titular, salvo se houver qualquer outra raz&atilde;o para sua manuten&ccedil;&atilde;o, por exemplo, cumprimento de quaisquer obriga&ccedil;&otilde;es legais, contratuais, ou para resguardar os direitos da Next Solu&ccedil;&otilde;es Imobili&aacute;rias.<br />
            <br /><b>7. Canal de Atendimento sobre privacidade de dados pessoais:</b><br /><br />
            Caso tenha d&uacute;vidas, coment&aacute;rios ou sugest&otilde;es relacionadas &agrave; privacidade dos seus dados pessoais ou queira exercer seus direitos, entre em contato conosco:<br /><br />
            Encarregado de dados <br/>marketing@nextsim.com.br<br/>(19) 2512-0000</p>
        </div>
      </div>
    </div>
  </section>
</div>
