<div id="backdrop">
  <div class="list-imoveis-menu-placeholder"></div>
  <section class="content properties">
    <div class="properties-content">
      <div class="container mt-5">
        <div class="row">
          <div class="col-lg-4 sidebar">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="">Home</a></li>
                <li class="breadcrumb-item"
                    *ngIf="!(customSearch?.customSearch?.categoria === 'comprar' || customSearch?.customSearch?.categoria === 'alugar') && !(customSearch?.customSearch?.finalidade === 'residencial' || customSearch?.customSearch?.finalidade === 'comercial')">
                  <a> Pesquisa </a></li>
                <li class="breadcrumb-item"
                    *ngIf="(customSearch?.customSearch?.categoria === 'comprar' || customSearch?.customSearch?.categoria === 'alugar') && (customSearch?.customSearch?.finalidade === 'residencial' || customSearch?.customSearch?.finalidade === 'comercial')">
                  <a> Imóveis </a></li>
                <li
                  class='breadcrumb-item active' [class.d-none]="breadcrumbTitle"
                  *ngIf="customSearch?.customSearch?.categoria === 'comprar' || customSearch?.customSearch?.categoria === 'alugar'">{{customSearch?.customSearch?.categoria === 'comprar' ? 'COMPRAR' : customSearch?.customSearch?.categoria === 'alugar' ? 'ALUGAR' : ('')}} </li>
                <li
                  class='breadcrumb-item active' [class.d-none]="breadcrumbTitle"
                  *ngIf="customSearch?.customSearch?.finalidade === 'residencial' || customSearch?.customSearch?.finalidade === 'comercial'">{{customSearch?.customSearch?.finalidade === 'residencial' ? 'RESIDENCIAIS' : customSearch?.customSearch?.finalidade === 'comercial' ? 'COMERCIAIS' : ('')}} </li>
                <li
                  class='breadcrumb-item active' [class.d-none]="breadcrumbTitle"
                  *ngIf="!(customSearch?.customSearch?.categoria === 'comprar' || customSearch?.customSearch?.categoria === 'alugar') && !(customSearch?.customSearch?.finalidade === 'residencial' || customSearch?.customSearch?.finalidade === 'comercial') && queryParams?.query">{{customSearch?.customSearch?.query}} </li>

                <li class='breadcrumb-item active' *ngIf="breadcrumbTitle">{{breadcrumbTitle}}</li>
              </ol>
            </nav>
            <div class="filter-badges">
              <span class="badge badge-pill" *ngFor="let badge of badges"
                    (click)="badgeClose(badge.query)">{{badge.label}}
                <button class="btn-badge" (click)="badgeClose(badge.query)">x</button></span>
            </div>
            <div class="row filter-inputs">
              <div class="col-lg-10">
                <div class="row">
                  <div class="col-12 d-flex mb-3 mt-1">
                    <input type="text" class="col" placeholder="Cidade, bairro ou código"
                           [(ngModel)]="simpleSearch"
                           (keyup)="searchAutocomplete($event)" id="userIdFirstWay" list="dynmicUserIdsImoveis"
                           (change)="onSelectAutoComplete($event, simpleSearch)"
                           [ngModelOptions]="{standalone: true}"/>
                    <datalist class="imoveisDatalist">
                      <option *ngFor="let item of autocompletes" [value]="item.value">{{item.value}}</option>
                    </datalist>
                    <button class="input-button" (click)="doSimpleSearch()"><i></i></button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button class="button-modal" (click)="openFilter()"><i></i>Filtro Personalizado</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 properties-list">
            <div class="d-block item no-result" *ngIf="(initializeResults) && (allImoveis?.length === 0 || imoveis?.length === 0)">
              <div class="row justify-content-center">
                <div class="col-auto">
                  <div class="lupa">

                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.4999 11H11.7099L11.4299 10.73C12.6299 9.33002 13.2499 7.42002 12.9099 5.39002C12.4399 2.61002 10.1199 0.390015 7.31989 0.0500152C3.08989 -0.469985 -0.470107 3.09001 0.0498932 7.32001C0.389893 10.12 2.60989 12.44 5.38989 12.91C7.41989 13.25 9.32989 12.63 10.7299 11.43L10.9999 11.71V12.5L15.2499 16.75C15.6599 17.16 16.3299 17.16 16.7399 16.75C17.1499 16.34 17.1499 15.67 16.7399 15.26L12.4999 11ZM6.49989 11C4.00989 11 1.99989 8.99002 1.99989 6.50002C1.99989 4.01002 4.00989 2.00002 6.49989 2.00002C8.98989 2.00002 10.9999 4.01002 10.9999 6.50002C10.9999 8.99002 8.98989 11 6.49989 11Z"
                        fill="#A3A3A3"/>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-12 col-sm-8">
                  <h4>Nenhum imóvel encontrado!</h4>
                  <p>Não encontramos nenhum resultado.<br/>Por favor, faça uma nova pesquisa.</p>

                </div>
              </div>
            </div>
            <div class="d-block d-lg-flex item no-gutters" *ngFor="let imovel of imoveis">
              <div class="col-lg-5 novo-slider h-100">
                <owl-carousel-o [options]="mySlideOptions">
                  <ng-container *ngFor="let image of imagensCarousel(imovel.midia.imagens); let i = index">
                    <ng-template carouselSlide [id]="i">
                      <div class="item" (click)="goImovel(imovel)"
                           style="height: 100%;">
                        <div class="img" [class.opacity-filter]="i === 3" style="height: 100%;">
                          <img [src]="image"/>
                          <a (click)="goImovel(imovel)" class="btn btn-see-more btn-rounded"
                             *ngIf="i === 3">
                            VER MAIS <span class="arrow-icon"></span>
                          </a>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>

                </owl-carousel-o>

              </div>
              <div class="col-lg-7 h-100 novo-content" (click)="goImovel(imovel)">
                <div class="h-100 row">
                  <div class="col-12 align-self-start novo-topo">
                    <div class="row">
                      <div class="col-12">
                        <span class="novo-tipo">{{imovel.tipo}}</span>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-12">
                        <span class="novo-local">{{imovel?.local?.bairro}}, {{imovel?.local?.cidade}}</span>
                      </div>

                    </div>
                  </div>
                  <div class="col-12 align-self-center novo-meio">
                    <div class="row no-gutters">
                      <div class="col-auto" *ngIf="toArea(imovel)">
                        <div>
                          <i class="icon-area"></i>
                          <span>{{toArea(imovel)}}</span>
                        </div>
                      </div>
                      <div class="col-auto"
                           *ngIf="(imovel.tipo === 'casa' && imovel.finalidade === 'comercial') && toSalas(imovel)">
                        <div><i class="icon2-salas"></i> <span>{{toSalas(imovel)}}</span></div>
                      </div>
                      <div class="col-auto"
                           *ngIf="(imovel.tipo !== 'sala' && imovel.tipo !== 'prédio' && imovel.finalidade !== 'comercial') && toDormis(imovel)">
                        <div><i class="icon-bed"></i> <span>{{toDormis(imovel)}}</span></div>
                      </div>
                      <div class="col-auto"
                           *ngIf="(imovel.tipo === 'sala' || imovel.tipo === 'prédio') && toSalas(imovel)">
                        <div><i class="icon2-salas"></i> <span>{{toSalas(imovel)}}</span></div>
                      </div>
                      <div class="col-auto" *ngIf="toBath(imovel)">
                        <div>
                          <i class="icon-bathtub"></i>
                          <span>{{toBath(imovel)}}</span>
                        </div>
                      </div>
                      <div class="col-auto" *ngIf="toVaga(imovel)">
                        <div>
                          <i class="icon-car"></i>
                          <span>{{toVaga(imovel)}}</span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-12 align-self-end novo-footer">
                    <div class="row justify-content-between">
                      <div class="col-auto">
                        <span class="novo-price" [innerHTML]="getprice(imovel)"></span>
                      </div>
                      <div class="col-auto align-self-end">
                        <span class="novo-cod">Cód: {{imovel.sigla}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="old" *ngIf="false">
                  <span class="field-label">{{imovel.tipo}}</span>
                  <p class="neighborhood">{{imovel?.local?.bairro}}</p>
                  <span class="code">Cód: <b>{{imovel.sigla}}</b></span>
                  <div class="middle-info">
                    <!-- Info Type -->
                    <div class="info-item"
                         *ngIf="(imovel.tipo === 'casa' && imovel.finalidade === 'comercial') && toSalas(imovel)">
                      <span class="field-label label-change">Salas</span>
                      <div><i class="icon2-salas text-orange"></i> {{toSalas(imovel)}}</div>
                    </div>
                    <div class="info-item"
                         *ngIf="(imovel.tipo !== 'sala' && imovel.tipo !== 'prédio' && imovel.tipo !== 'casa' && imovel.finalidade !== 'comercial') && toDormis(imovel)">
                      <span class="field-label label-change">Quartos</span>
                      <div><i class="icon-bed text-orange"></i> {{toDormis(imovel)}}</div>
                    </div>
                    <div class="info-item"
                         *ngIf="(imovel.tipo === 'sala' || imovel.tipo === 'prédio') && toSalas(imovel)">
                      <span class="field-label label-change">Salas</span>
                      <div><i class="icon2-salas text-orange"></i> {{toSalas(imovel)}}</div>
                    </div>
                    <div class="info-item" *ngIf="toArea(imovel)">
                      <span class="field-label label-change" *ngIf="imovel.tipo !== 'cobertura'">Área</span>
                      <span class="field-label label-change" *ngIf="imovel.tipo === 'cobertura'">Área útil</span>
                      <div>
                        <i class="icon-area text-orange"></i>
                        {{toArea(imovel)}}
                      </div>
                    </div>
                    <div class="info-item">
                      <span class="field-label label-change" *ngIf="queryParams.categoria === 'comprar'">Preço</span>
                      <span class="field-label label-change" *ngIf="queryParams.categoria === 'alugar'">Aluguel</span>
                      <div><i class="icon-dollar text-orange"></i> {{getprice(imovel)}}</div>
                    </div>
                  </div>
                  <div class="footer-info">
                    <div class="row">
                      <div class="col-6 col-sm-6 pos-btn-see-more">
                        <a (click)="goImovel(imovel)"
                           class="btn btn-see-more btn-rounded">
                          VER MAIS <span class="arrow-icon"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12" [hidden]="allImoveis?.length <= 10">
              <ngb-pagination [collectionSize]="pages" [(page)]="currentPage" [maxSize]="5" [rotate]="true"
                              [boundaryLinks]="true" (pageChange)="changePage($event)"></ngb-pagination>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12">
        <div class="mood">
          <div class="mood-title">
            <p>Imóveis para</p>
            <h4>Todos os momentos</h4>
          </div>
          <div class="mood-content">
            <div class="row mood">
              <div class="col-12 col-md mood-bg mood-card building px-0 mx-0" style="cursor:pointer;">
                <a
                  [routerLink]="'/imoveis/para-construir'">
                  <div class="blend-mode">
                    <div class="mood-description">
                      <i class="icon-wall h4"></i>
                      <h3>Para construir</h3>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-12 col-md mood-bg mood-card highlight px-0 mx-0" style="cursor:pointer;">
                <a [routerLink]="'/imoveis/destaques'">
                  <div class="blend-mode">
                    <div class="mood-description">
                      <i class="icon-featured h4"></i>
                      <h3>Destaques</h3>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-12 col-md mood-bg mood-card for-family px-0 mx-0" style="cursor:pointer;">
                <a
                  [routerLink]="'/imoveis/para-familia'">
                  <div class="blend-mode">
                    <div class="mood-description">
                      <i class="icon-house-heart h4"></i>
                      <h3>Para família</h3>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-12 col-md mood-bg mood-card for-couple px-0 mx-0" style="cursor:pointer;">
                <a
                  [routerLink]="'/imoveis/para-casal'">
                  <div class="blend-mode">
                    <div class="mood-description">
                      <i class="icon-alliance h4"></i>
                      <h3>Para casal</h3>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-12 col-md mood-bg mood-card alone px-0 mx-0" style="cursor:pointer;">
                <a
                  [routerLink]="'/imoveis/morando-sozinho'">
                  <div class="blend-mode">
                    <div class="mood-description">
                      <i class="icon-avatar-alone"></i>
                      <h3>Morando sozinho</h3>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<app-custom-search #customSearch (customSearchChange)="queryChange($event)"
                   (simpleSearchChange)="softParamSearch($event)"></app-custom-search>
