<div id="backdrop" class="backdrop-imovel" *ngIf="imovel">

  <section class="property container-fluid">
    <div class="ResidencialHeaderContainer container mb-3 mb-md-0">
      <div class="row">
        <div class="col-lg-12 noPadding">
          <a (click)="back()" class="PropertyBackLink pl-4 pl-sm-4 pl-md-4 pl-lg-3 hover-me"><i
            class="PropertyArrowBackBtn"></i>
            Voltar aos resultados</a>

        </div>
      </div>

      <div class="row">
        <div class="col-xl-7 col-lg-7 col-md-12 ">
          <ng-image-slider [images]="imgs" [imageSize]="{width: '100%', height: imageheight}"></ng-image-slider>
          <div class="full-image"></div>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-12">
          <div class="card-imovel">
            <div class="h-100 row">
              <div class="col-12 align-self-start novo-topo">
                <div class="row">
                  <div class="col-12">
                    <span class="novo-tipo">{{imovel.tipo}}</span> <span
                    class="novo-cod-mobile">, cód: {{imovel.sigla}}</span>
                  </div>

                </div>
                <div class="row">
                  <div class="col-12">
                    <span class="novo-local">{{imovel?.local?.bairro}}, {{imovel?.local?.cidade}}</span>
                  </div>

                </div>
              </div>
              <div class="col-12 align-self-center novo-meio">
                <div class="row no-gutters">
                  <div class="col-auto" *ngIf="toArea(imovel)">
                    <div>
                      <i class="icon-area"></i>
                      <span>{{toArea(imovel)}}</span>
                    </div>
                  </div>
                  <div class="col-auto"
                       *ngIf="(imovel.tipo === 'casa' && imovel.finalidade === 'comercial') && toSalas(imovel)">
                    <div><i class="icon2-salas"></i> <span>{{toSalas(imovel)}}</span></div>
                  </div>
                  <div class="col-auto"
                       *ngIf="(imovel.tipo !== 'sala' && imovel.tipo !== 'prédio' && imovel.finalidade !== 'comercial') && toDormis(imovel)">
                    <div><i class="icon-bed"></i> <span>{{toDormis(imovel)}}</span></div>
                  </div>
                  <div class="col-auto"
                       *ngIf="(imovel.tipo === 'sala' || imovel.tipo === 'prédio') && toSalas(imovel)">
                    <div><i class="icon2-salas"></i> <span>{{toSalas(imovel)}}</span></div>
                  </div>

                  <div class="col-auto" *ngIf="toBath(imovel)">
                    <div>
                      <i class="icon-bathtub"></i>
                      <span>{{toBath(imovel)}}</span>
                    </div>
                  </div>
                  <div class="col-auto" *ngIf="toVaga(imovel)">
                    <div>
                      <i class="icon-car"></i>
                      <span>{{toVaga(imovel)}}</span>
                    </div>
                  </div>
                </div>


              </div>
              <div class="col-12 align-self-end novo-footer">
                <div class="row prices-table">
                  <div class="col-12">
                    <div class="row justify-content-between line align-items-center" *ngIf="imovel?.comercializacao?.venda?.ativa">
                      <div class="col-auto"><span><b>Venda</b></span></div>
                      <div class="col-auto"><span
                        class="hard-color digit">{{getFormattedPrice(imovel?.comercializacao?.venda?.preco)}}</span></div>
                    </div>
                    <div class="row justify-content-between line align-items-center" *ngIf="imovel?.comercializacao?.locacao?.ativa">
                      <div class="col-auto"><span><b class="hard-color">Aluguel</b></span></div>
                      <div class="col-auto"><span
                        class="hard-color digit">{{ getFormattedPrice(imovel?.comercializacao?.locacao?.preco)}}</span>
                      </div>
                    </div>
                    <div class="row justify-content-between line align-items-center" *ngIf="imovel?.comercializacao?.taxa?.condominio">
                      <div class="col-auto"><span>Condomínio <i class="info_icon align-self-center" (click)="openToast('Condomínio', 'Valor sujeito a alteração conforme deliberações condominiais, podendo, ainda, variar de acordo com as despesas fixas e eventuais do condomínio (ex.: água, luz, conservação e manutenção do prédio, entre outros).')"></i></span></div>
                      <div class="col-auto"><span class="digit">{{getFormattedPrice(imovel?.comercializacao?.taxa?.condominio)}}</span>
                      </div>
                    </div>
                    <div class="row justify-content-between line align-items-center"
                         *ngIf="imovel?.comercializacao?.taxa?.iptu && imovel?.comercializacao?.locacao?.ativa">
                      <div class="col-auto"><span>IPTU <i class="info_icon align-self-center" (click)="openToast('IPTU', 'O IPTU é um imposto municipal cujo valor é determinado pela prefeitura.')"></i></span></div>
                      <div class="col-auto"><span class="digit">{{getFormattedPrice(imovel?.comercializacao?.taxa?.iptu)}}<span
                        *ngIf="imovel?.comercializacao?.taxa?.mes">/{{anualOrMonth(imovel?.comercializacao?.taxa?.mes)}}</span></span>
                      </div>
                    </div>
                    <div class="row justify-content-between line-hr align-items-center"
                         *ngIf="imovel?.comercializacao?.locacao?.ativa">
                      <div class="col-12">
                        <hr/>
                      </div>
                    </div>
                    <div class="row justify-content-between line mb-2 mt-1" *ngIf="imovel?.comercializacao?.locacao?.ativa">
                      <div class="col-auto"><span><b>Total</b></span></div>
                      <div class="col-auto"><span class="digit">R$ <b>{{theTotal()}}</b></span></div>
                    </div>
                  </div>
                </div>

                <div class="row ">
                  <div class="col-12 ">
                    <button class="more" (click)="open(content)">Quero saber mais</button>
                  </div>
                  <div class="col-12 ">
                    <button class="share" (click)="whatsapp()">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.9 42">
                        <path class="a"
                              d="M35.7,6.1a22.1,22.1,0,0,1,4.6,6.8,19.584,19.584,0,0,1,1.6,7.9A19.886,19.886,0,0,1,39,31.2a21.567,21.567,0,0,1-7.7,7.6,20.149,20.149,0,0,1-10.5,2.8,19.693,19.693,0,0,1-9.9-2.5L0,42,3,31.2A19.8,19.8,0,0,1,.2,20.8,19.8,19.8,0,0,1,3,10.4a21.29,21.29,0,0,1,7.6-7.6A19.8,19.8,0,0,1,21,0a19.249,19.249,0,0,1,7.9,1.6A23.678,23.678,0,0,1,35.7,6.1ZM21,38.2a17.515,17.515,0,0,0,15.1-8.6,16.269,16.269,0,0,0,2.4-8.7,17.392,17.392,0,0,0-1.4-6.6,17.045,17.045,0,0,0-3.9-5.7,17.383,17.383,0,0,0-5.6-3.8A16.538,16.538,0,0,0,21,3.5a17.679,17.679,0,0,0-8.7,2.3A17.3,17.3,0,0,0,6,12.1a16.192,16.192,0,0,0-2.3,8.7A16.755,16.755,0,0,0,6.4,30l.4.7L5,37l6.6-1.7.7.4A16.351,16.351,0,0,0,21,38.2Zm9.5-13.1.3.2c.4.2.7.3.8.5s.1.4,0,.9a8.331,8.331,0,0,1-.4,1.5,3.676,3.676,0,0,1-1.5,1.6,6.533,6.533,0,0,1-2,.9,10.445,10.445,0,0,1-2.5,0,21.344,21.344,0,0,1-3.8-1.3,20.8,20.8,0,0,1-8.7-7.7l-.2-.2a9.224,9.224,0,0,1-2.1-5.3A6.069,6.069,0,0,1,12.1,12l.1-.1a2.1,2.1,0,0,1,1.4-.7h1.1c.2,0,.5,0,.6.1a1.4,1.4,0,0,1,.4.7l1.6,3.8a1.009,1.009,0,0,1,.1.9,6.57,6.57,0,0,1-1.2,1.7c-.2.2-.4.5-.5.6s0,.3.2.5A11.97,11.97,0,0,0,19,23.3a21.079,21.079,0,0,0,3.9,2.3c.5.2.9.2,1.1-.1a17.261,17.261,0,0,0,1.7-2.1.664.664,0,0,1,.5-.4,1.952,1.952,0,0,1,.7.1A16.048,16.048,0,0,0,30.5,25.1Z"/>
                      </svg>
                      Compartilhar
                    </button>
                  </div>
                </div>
                <div class="row justify-content-end ">
                  <div class="col-auto">
                    <span class="novo-cod">Cód: {{imovel.sigla}}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row" *ngIf="false">

            <div class="col-md-12">
              <div class="PropertyCardDetails PropertyCardShadowBox">

                <!--<p class="PropertyCardType">Apartamento | Área
            total: 137 m²</p>-->

                <p class="PropertyCardType">
                  <span class="first_letter">{{imovel.tipo}}</span> |
                  <span
                    *ngIf="imovel.tipo ==='apartamento'">Área útil {{imovel.numeros?.areas?.util?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>
                  <span
                    *ngIf="imovel.tipo ==='sala'">Área útil {{imovel.numeros?.areas?.util?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>
                  <span
                    *ngIf="imovel.tipo ==='cobertura'">Área útil {{imovel.numeros?.areas?.util?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>
                  <span
                    *ngIf="imovel.tipo ==='casa' && imovel.numeros?.areas?.terreno">Área do Terreno {{imovel.numeros?.areas?.terreno?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}
                    | </span>
                  <span
                    *ngIf="imovel.tipo ==='casa' && imovel.numeros?.areas?.construida">Área Construída {{imovel.numeros?.areas?.construida?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>
                  <span
                    *ngIf="imovel.tipo ==='chácara' && imovel.numeros?.areas?.terreno">Área do Terreno {{imovel.numeros?.areas?.terreno?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}
                    | </span>
                  <span
                    *ngIf="imovel.tipo ==='chácara' && imovel.numeros?.areas?.construida">Área Construída {{imovel.numeros?.areas?.construida?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>
                  <span
                    *ngIf="imovel.tipo ==='prédio' && imovel.numeros?.areas?.terreno">Área do Terreno {{imovel.numeros?.areas?.terreno?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}
                    | </span>
                  <span
                    *ngIf="imovel.tipo ==='prédio' && imovel.numeros?.areas?.construida">Área Construída {{imovel.numeros?.areas?.construida?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>
                  <span
                    *ngIf="imovel.tipo ==='galpão'">Área do Terreno {{imovel.numeros?.areas?.terreno?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}
                    | </span>
                  <span
                    *ngIf="imovel.tipo ==='galpão'">Área Construída {{imovel.numeros?.areas?.construida?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>
                  <span
                    *ngIf="imovel.tipo ==='terreno'">Área do Terreno {{imovel.numeros?.areas?.terreno?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>
                  <span
                    *ngIf="imovel.tipo ==='área'">Área do Terreno {{imovel.numeros?.areas?.terreno?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>


                  <span
                    *ngIf="imovel.tipo !=='apartamento' && imovel.tipo !=='casa' && imovel.tipo !=='galpão' && imovel.tipo !=='terreno' && imovel.tipo !=='área' && imovel.tipo !=='chácara' && imovel.tipo !=='sala' && imovel.tipo !=='prédio' && imovel.tipo !=='cobertura'">Área total {{imovel.numeros?.areas?.total?.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>
                  <!--                  <span>Área útil {{imovel.numeros?.areas?.util.toFixed(0)}} {{imovel.numeros?.areas?.unidade}}</span>-->
                </p>
                <p class="PropertyCardTitle">{{imovel.local.bairro}}</p>
                <p class="PropertyCardNeighboor"><img
                  src="../../../assets/img/MapPointer-orenge.png"/> {{imovel.local.bairro}} ,
                  {{imovel.local.cidade}}</p>

                <ul class="PropertyOptions">
                  <li *ngIf="imovel.numeros.dormitorios || imovel.numeros.salas">

                    <img src="../../assets/img/imovel/bed-orange.svg" *ngIf="imovel.finalidade === 'residencial'"/>
                    <span><i class="icon2-salas text-orange" *ngIf="imovel.finalidade !== 'residencial'"
                             style="margin-right: 10px;"></i>{{imovel.finalidade === 'residencial' ? imovel.numeros.dormitorios : imovel.numeros.salas }}</span>
                  </li>
                  <li *ngIf="imovel.numeros.banheiros"><img src="../../assets/img/imovel/shower-orange.svg"/>
                    <span>{{imovel.numeros.banheiros}}</span>
                  </li>
                  <li class="last-item" *ngIf="imovel.numeros.vagas"><img src="../../assets/img/imovel/car-orange.svg"/>
                    <span>{{imovel.numeros.vagas}}</span>
                  </li>
                </ul>

                <div class="PropertyCardValues valores pt-4 row"
                     [class.iptu]="imovel?.comercializacao?.taxa?.iptu && imovel?.comercializacao?.locacao?.ativa"
                     [class.condominio]="imovel?.comercializacao?.taxa?.condominio"
                     *ngIf="(imovel?.comercializacao?.taxa?.iptu && imovel?.comercializacao?.locacao?.ativa) || imovel?.comercializacao?.taxa?.condominio">
                  <img src="../../assets/img/imovel/coin-orange.svg"/>
                  <div class="" style="display: inline-block;">

                  <span
                    *ngIf="imovel?.comercializacao?.taxa?.iptu && imovel?.comercializacao?.locacao?.ativa">IPTU: R$ {{imovel?.comercializacao?.taxa?.iptu}}
                    <span
                      *ngIf="imovel?.comercializacao?.taxa?.mes">/{{anualOrMonth(imovel?.comercializacao?.taxa?.mes)}}</span></span>
                    <br
                      *ngIf="imovel?.comercializacao?.taxa?.iptu  && imovel?.comercializacao?.locacao?.ativa && imovel?.comercializacao?.taxa?.condominio"/>
                    <span
                      *ngIf="imovel?.comercializacao?.taxa?.condominio">Condomínio: R$ {{imovel?.comercializacao?.taxa?.condominio}}
                      <!--                      <span *ngIf="imovel?.comercializacao?.taxa?.mes">/{{anualOrMonth(imovel?.comercializacao?.taxa?.mes)}}</span></span>-->
                      <span *ngIf="imovel?.comercializacao?.taxa?.mes"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-8 col-sm-8 col-lg-9 col-xl-9  pt-1 mt-2">
              <div class="PropertyCard PropertyCardShadowBox" style="align-items: start; flex-direction: column;">

                <p class="PropertyCardValues " *ngIf="imovel?.comercializacao?.venda?.ativa">
                  <img
                    src="../../assets/img/imovel/coin-gray.svg"/> Venda:
                  <b>{{getFormattedPrice(imovel?.comercializacao?.venda?.preco)}}</b>
                </p>
                <p class="PropertyCardValues " *ngIf="imovel?.comercializacao?.locacao?.ativa">
                  <img
                    src="../../assets/img/imovel/coin-gray.svg"/> Aluguel:
                  <b>{{ getFormattedPrice(imovel?.comercializacao?.locacao?.preco)}}</b>
                </p>

              </div>

            </div>

            <div class="col-4 col-sm-4 col-lg-3 col-xl-3  pl-0 pl-xl-0  pt-1 mt-2 ">
              <div class="PropertyCard PropertyCardShadowBox">
                <p class="PropertyCardCod">Código:<b><br/>{{imovel.sigla}}</b></p>
              </div>
            </div>

            <div class="col-md-12 pt-1 mt-2">
              <button type="button" class="btn btn-block PropertyCardBTN PropertyCardShadowBox" (click)="open(content)">
                Quero saber mais
              </button>

              <button type="button" class="btn btn-block PropertyCardBTN PropertyCardShadowBox" (click)="whatsapp()">
                <img _ngcontent-sgc-c2="" alt="" class="mr-1" src="assets/img/icons/icon-whatsapp.svg" title=""
                     width="22">
                Compartilhar
              </button>
            </div>
            <div
              class="col-md-6 pt-4  align-self-center text-center d-none d-sm-none d-md-block btn-add-wishlist-login btn-wishlist"
              style="display: none !important;">
              <img src="../../assets/img/ADDWishListBTN.png" style="max-width: 100%;"
                   alt="Adicionar a lista de desejos">
            </div>

            <div class="col-md-6 pt-4 align-self-center text-center d-none d-sm-none d-md-block btn-wishlist"
                 style="display: none !important;">
              <a data-toggle="collapse" data-target="#share"><img src="../../assets/img/ShareBTN.png"
                                                                  style="max-width: 100%;" alt="Compartilhar"></a>
            </div>

            <div id="share" class="collapse col-md-12 mx-auto" style="display: none !important;">
              <div class="mt-3 text-right">

                <!-- Go to www.addthis.com/dashboard to customize your tools -->
                <div class="addthis_inline_share_toolbox"></div>

              </div>
            </div>

          </div>
        </div>
        <div class="col-md-12  d-none d-sm-none d-md-block pb-2">
          <img class="mx-auto d-none d-sm-none d-md-block" id="down-arrow" src="../../assets/img/arrow-04.png"
               width="50" (click)="scroll()">
        </div>
      </div>

    </div>

  </section>

  <section class="container desc">
    <div class="row">
      <div class="col-12 PropertyDescription">
        <h3>Descrição</h3>
        <p>
          {{imovel.detalhes.descricaosite}}
        </p>
      </div>
    </div>
    <div class="row">
        <div class="container">
          <div class="row">
            <div class="col recursos" *ngIf="imovel?.recursos?.imovel?.length > 0">
              <h3>Recursos do Imóvel</h3>
              <ul>
                <li *ngFor="let recurso of imovel.recursos.imovel">{{recurso}}</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col recursos" *ngIf="imovel?.recursos?.condominio?.length > 0">
              <h3>Recursos do Condomínio</h3>
              <ul>
                <li *ngFor="let recurso of imovel.recursos.condominio">{{recurso}}</li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  </section>

  <!-- INICIO SESSAO MAPA -->
  <section class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="PropertyMapText" style="display:none !important;">
          <p>Conheça esse imóvel com
            <br/>um dos nossos corretores
            <br/>ou tire suas dúvidas pelo
            <br/><b>fale com a gente.</b></p>
          <a class="btn btn-rounded PropertyMapButton" data-toggle="modal" data-target="#propContactMe">Quero
            visitar</a>
        </div>

        <div class="row " *ngIf="imovel?.local?.coordenadas && apiLoaded | async">
          <google-map class="w-100" [center]="{lat: imovel?.local?.coordenadas[0], lng: imovel?.local?.coordenadas[1]}"
                      [zoom]="14">
            <map-circle [center]="{lat: imovel?.local?.coordenadas[0], lng: imovel?.local?.coordenadas[1]}"
                        [options]="{fillColor: '#fe6900', strokeOpacity: 0}"
                        [radius]="500"></map-circle>
            <map-circle [center]="{lat: imovel?.local?.coordenadas[0], lng: imovel?.local?.coordenadas[1]}"
                        [options]="{fillColor: '#fe6900', strokeOpacity: 0}"
                        [radius]="1000"></map-circle>
            <map-circle [center]="{lat: imovel?.local?.coordenadas[0], lng: imovel?.local?.coordenadas[1]}"
                        [options]="{fillColor: '#fe6900', strokeOpacity: 0}"
                        [radius]="1500"></map-circle>
          </google-map>
        </div>
      </div>
    </div>
  </section>
  <!-- FINAL SESSAO MAPA -->


  <app-next-toast #toast></app-next-toast>

  <ng-template #content let-modal>
    <div class="modal-content">
      <form [formGroup]="form" class="form-card "
            id="form-negotiate-property">

        <div class="card text-center">
          <div class="card-header text-dark-gray bg-gray-100 font-weight-bolder text-uppercase py-3">
            Entre em contato
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="card-body text-left bg-gray-100 p-2 pt-5">
            <div class="row">
              <div class="offset-md-1 offset-lg-1 col-12 col-md-10 col-lg-10">
                <div class="form-group">
                  <div class="form-icon">
                    <span id="validate_contact_name"></span>
                    <input type="text" class="form-control orange-input" name="form-contact-name"
                           id="form-contact-name"
                           formControlName="nome"
                           placeholder="Nome completo" autocomplete="off" required="required">
                    <span id="alert_contact_name" class="float-left blur-error"></span>
                  </div>
                </div>
              </div>

              <div class="offset-md-1 offset-lg-1 col-12 col-md-10 col-lg-10">
                <div class="form-group">
                  <div class="form-icon">
                    <span id="validate_contact_phone"></span>
                    <input type="text" class="form-control phone orange-input" name="form-contact-phone"
                           id="form-contact-phone" placeholder="Telefone" autocomplete="off" required="required"
                           formControlName="telefone" [textMask]="{mask: MASKS.celular.textMask}">
                  </div>
                  <span id="alert_contact_phone" class="float-left blur-error"></span>
                </div>
              </div>

              <div class="offset-md-1 offset-lg-1 col-12 col-md-10 col-lg-10">
                <div class="form-group">
                  <div class="form-icon">
                    <span id="validate_contact_email"></span>
                    <input type="email" class="form-control orange-input" name="form-contact-email"
                           id="form-contact-email" value="" placeholder="Digite seu e-mail aqui" autocomplete="off"
                           formControlName="email"
                           required="required">
                  </div>
                  <span id="alert_contact_email" class="float-left blur-error"></span>
                </div>
              </div>

              <div class="offset-md-1 offset-lg-1 col-12 col-md-10 col-lg-10">
                <div class="form-group ">
                  <label for="form-contact-message">Mensagem</label>
                  <span id="alert_message" class="float-right blur-error"></span>
                  <textarea class="form-control orange-textarea" name="form-contact-message" id="form-contact-message"
                            rows="3"
                            placeholder="Mensagem (Opcional)" formControlName="mensagem"></textarea>
                </div>
              </div>
              <div class="col-12 text-center my-4">
                <button class="btn btn-sing-up btn-rounded btn-primary text-uppercase mt-3 btn-negotiate-modal"
                        id="btn-negotiate-modal" [disabled]="!form.valid"
                        (click)="submitForm()">
                  Enviar
                  <img class="btn-invisible" src="../../assets/img/spinner.gif" id="negotiate_modal_spinner"
                       width="23"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

</div>
