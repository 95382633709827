import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import IAutoComplete from '../../types/IAutoComplete';
import { AutocompleteDropdownService } from '../../services/autocomplete-dropdown.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class MobileSearchComponent implements OnInit {

  // atributos
  bairros: Array<{ type: string; value: string, locacao: boolean, venda: boolean }> = [];
  cidades: Array<{ type: string; value: string, locacao: boolean, venda: boolean }> = [];
  condominios: Array<{ type: string; value: string, locacao: boolean, venda: boolean }> = [];
  ruas: Array<{ type: string; value: string, locacao: boolean, venda: boolean }> = [];
  private subDropDown: Subscription;
  activeInput:boolean = true;

  // Props
  @Input() simpleSearch: string = '';
  @Input() autocompletes: IAutoComplete[] = [];
  @Input() categoria: string = '';
  @Input() finalidade: string = '';

  // Emição de eventos
  @Output() closeMobileSearchBar = new EventEmitter();
  @Output() inputSearch = new EventEmitter<string>();
  @Output() selectedOption = new EventEmitter<string>();
  @Output() submitFilter = new EventEmitter();

  // Refs
  @ViewChild('inputElement') input:ElementRef;
  constructor(
    private cdr:ChangeDetectorRef,
    private dropdown: AutocompleteDropdownService
  ) { }

  ngOnInit(): void {

    this.subDropDown = this.dropdown.getAutoComplete$().subscribe((val:boolean) => {
      this.activeInput = val;
    })

  }

  ngAfterViewInit(): void {
    this.inputFocus();
  }

  inputFocus():void{
    this.input.nativeElement.focus();
    this.cdr.detectChanges();
  }

  handleInput(value:string): void {
    this.outputNewSimpleSearch(value);
    this.autoComplete(value);
  }

  closeModal(){
    this.input.nativeElement.blur();
    this.closeMobileSearchBar.emit();
  }

  outputNewSimpleSearch(value: string){
    this.inputSearch.emit(value);
  }

  autoComplete(value: string): void{
    if (value === null || value === undefined) return;
    if (value.trim() === '') return;

    if (value.length >= 3){
      const lowerValue = value.toLowerCase();
      const bairros = this.filterLocales(lowerValue, 'bairro');
      const cidades = this.filterLocales(lowerValue, 'cidade');
      const condominios = this.filterLocales(lowerValue, 'condominio');
      const ruas = this.filterLocales(lowerValue, 'rua');

      this.bairros = Array.from(new Map(bairros.map(item => [item.value.toLowerCase(), item])).values());
      this.cidades = Array.from(new Map(cidades.map(item => [item.value.toLowerCase(), item])).values());
      this.condominios = Array.from(new Map(condominios.map(item => [item.value.toLowerCase(), item])).values());
      this.ruas = Array.from(new Map(ruas.map(item => [item.value.toLowerCase(), item])).values());
    } else {
      this.bairros = [];
      this.cidades = [];
      this.condominios = [];
      this.ruas = [];
    }
    this.cdr.detectChanges();
  }

  private filterLocales(value: string, type: string): IAutoComplete[] {
    let filterCategorie: IAutoComplete[] = this.autocompletes;
    if (this.categoria !== '') {
      filterCategorie = this.autocompletes.filter((imovel) => {
        if (this.categoria === 'comprar'){
          return imovel.venda === true;
        } else {
          return imovel.locacao === true;
        }
      });
    }
    if (this.finalidade){
      filterCategorie = this.autocompletes.filter((imovel) => imovel.finalidade === this.finalidade);
    }
    return filterCategorie.filter(item =>
      item.type === type && item.value.toLowerCase().includes(value)
    ) || [];
  }

  private toCapitalizeCase(val: string): string {
    const street = this.formatedStreet(val)
    const texts: string[] = street.split(' ');
    const formated: string[] = [];
    for (let i = 0; i < texts.length; i++){
      let text: string = '';
      for (let j = 0; j < texts[i].length; j++){
        if (j === 0){
          text += texts[i][j].toUpperCase();
        } else {
          text += texts[i][j].toLowerCase();
        }
      }
      formated.push(text);
    }

    return formated.join(' ');
  }

  selectedEvent(value: string): void{
    this.closeModal();
    this.selectedOption.emit(this.toCapitalizeCase(value));
  }

  search(event: Event): void{
    event.preventDefault();
    this.submitFilter.emit();
    this.closeMobileSearchBar.emit();
  }

  formatedStreet(val: string): string {
    return val.replace(/^rua\s+/i, "").replace(/^Rua\s+/i, '').replace(/^Rua:\s+/i, '').replace(/^Rua.\s+/i, '').replace(/^RUA\s+/i, '').toLocaleLowerCase();
  }

  ngOnDestroy(){
    this.subDropDown.unsubscribe();
  }
}
