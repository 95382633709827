import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-pessoa-fisica',
  templateUrl: './form-pessoa-fisica.component.html',
  styleUrls: ['./form-pessoa-fisica.component.scss']
})
export class FormPessoaFisicaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  submit() {

  }
}
