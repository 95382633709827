<div id="backdrop">
  <section class="container-fluid d-block d-sm-block d-md-none bg-gray-800">
    <div class="row pt-5">

      <div class="col-12 col-sm-12 p-0">
        <img class="img-fluid" src="assets/img/frente-predio-next-sobre-nos.png"/>
      </div>

      <div class="col-12 col-sm-12 pt-2 text-center">
        <span class="title-bold pb-4">Sobre a Next</span>
        <p class="text-aboutus col-12 ">Nascemos e vivemos nas melhores regiões de Campinas, e unindo isto
          à nossa experiência empresarial e visão de investimento, possuímos a expertise necessária para
          realizar os melhores negócios imobiliários.</p>
        <p class="text-aboutus col-12 ">E, para que isso aconteça, a Next orienta e atua do início ao fim,
          em todo o processo de análise e negociação imobiliária.</p>
        <p class="text-aboutus col-12 ">Se você quer alugar, vender, investir com segurança ou comprar o
          imóvel que você procura com rentabilidade otimizada, venha pra Next.</p>
      </div>

      <div class="col-12 col-sm-12 text-center pb-5">
        <img src="assets/img/arrow-01.png" width="50" id="down-arrow">
      </div>

    </div>
  </section>

  <section class="container-fluid d-block d-sm-block d-md-none bg-black">
    <div class="row">
      <div class="col-6 px-0">
        <img src="assets/img/fachada-next-solucoes-imobiliarias.jpg" alt="Prédio Next Soluções Imobiliárias"
             class="img-fluid px-0">
      </div>
      <div class="col-6 px-0">
        <div class="row">
          <div class="col-12">
            <img src="assets/img/interna-recepcao-next-solucoes-imobiliarias.jpg"
                 alt="Interna Recepção Next Soluções Imobiliárias" class="img-fluid px-0">
          </div>
          <div class="col-12">
            <img src="assets/img/planta-predio-next-solucoes-imobiliarias.jpg"
                 alt="Planta Prédio Next Soluções Imobiliárias" class="img-fluid px-0">
          </div>
        </div>
      </div>
      <div class="col-12 px-0">
        <img src="assets/img/escritorio-next-solucoes-imobiliarias.jpg" alt="EscritórioNext Soluções Imobiliárias"
             class="img-fluid px-0">
      </div>
    </div>
  </section>

  <section class="container-fluid d-block d-sm-block d-md-none bg-gray-mobile">
    <div class="row pt-5">
      <div class="col-12 col-sm-12 text-center">
        <span class="title-regular pb-3 text-gray">Detalhes que nos tornam diferenciados</span>
        <span class="orange_bar_decoration"></span>
      </div>

      <div class="col-12 pt-5 pb-5 mx-auto">
        <ul class="list-unstyled text-dark-gray">
          <li class="media">
            <i class="icon-sede h1"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">SEDE</p>
              <p class="txt3">Moderna e confiável, ideal para receber nossos clientes. A Next traz comodidade para
                você.</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-hands h1"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">PROFISSIONAIS</p>
              <p class="txt3">Consultores de venda e locação, especialistas em suas respectivas áreas de atuação.</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-target h1 ml-2 mr-3"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">MARKETING</p>
              <p class="txt3">Apresentação dos imóveis com qualidade diferenciada.</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-building h1 ml-2 mr-3"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">PRODUTOS</p>
              <p class="txt3">Os melhores imóveis de Campinas e região. Ampla carteira de imóveis, com grande
                variedade de produtos.</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-card-company h1 mr-2"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">DIVULGAÇÃO</p>
              <p class="txt3">Forte presença na web, explorando as mais modernas e eficientes ferramentas,
                proporcionando maior visibilidade para os imóveis</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-juridical h1 ml-2 mr-3"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">JURÍDICO</p>
              <p class="txt3">Departamento interno que acompanha todo o processo do negócio imobiliário.</p>
            </div>
          </li>
        </ul>
      </div>

    </div>
  </section>

  <section class="container-fluid d-block d-sm-block d-md-none bg-ice">
    <ul class="list-unstyled pt-5 mt-3">
      <li class="media" *ngFor="let historia of historias">
        <img class="img-fluid ml-1 mt-1" src="assets/img/icons/icon-tl-start.png"
             alt="EscritórioNext Soluções Imobiliárias">
        <div class="media-body mx-4">
          <p class="text-dark-gray mr-4 tl-title">{{historia.data}}</p>
          <p class="text-gray-300 tl-text">
            {{historia.texto}}
          </p>
        </div>
      </li>

    </ul>
  </section>

  <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block bg-gray-800">
    <div class="content-internal container-fluid about-us">
      <div class="row">
        <div class="col-lg-6 px-0">
          <img src="assets/img/frente-predio-next-sobre-nos.png" alt="Prédio Next Soluções Imobiliárias"
               class="img-fluid px-0">
        </div>
        <div class="col-lg-6 d-flex">
          <div class="d-flex align-items-center">
            <div class="row">
              <div class="col-12 pl-0 pl-md-5">
                <div class="vertical-align text-left pr-5">
                  <p class="text-white txt1-titule ">Sobre a Next</p>
                  <p class="text-gray-300 txt1 pr-5">Nascemos e vivemos nas melhores regiões de Campinas, e unindo isto
                    à nossa experiência empresarial e visão de investimento, possuímos a expertise necessária para
                    realizar os melhores negócios imobiliários.</p>
                  <p class="text-gray-300 txt1 pr-5">E, para que isso aconteça, a Next orienta e atua do início ao fim,
                    em todo o processo de análise e negociação imobiliária.</p>
                  <p class="text-gray-300 txt1 pr-5">Se você quer alugar, vender, investir com segurança ou comprar o
                    imóvel que você procura com rentabilidade otimizada, venha pra Next.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container-fluid content d-none d-sm-none d-md-block d-lg-block d-xl-block">
    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-6 px-0">
            <img src="assets/img/fachada-next-solucoes-imobiliarias.jpg" alt="Prédio Next Soluções Imobiliárias"
                 class="img-fluid px-0">
          </div>
          <div class="col-6 px-0">
            <div class="row">
              <div class="col-12">
                <img src="assets/img/interna-recepcao-next-solucoes-imobiliarias.jpg"
                     alt="Interna Recepção Next Soluções Imobiliárias" class="img-fluid px-0">
              </div>
              <div class="col-12">
                <img src="assets/img/planta-predio-next-solucoes-imobiliarias.jpg"
                     alt="Planta Prédio Next Soluções Imobiliárias" class="img-fluid px-0">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 px-0">
            <img src="assets/img/escritorio-next-solucoes-imobiliarias.jpg" alt="EscritórioNext Soluções Imobiliárias"
                 class="img-fluid px-0">
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="title-internal pt-1 pb-1">
          <p class="txt2-title">Detalhes que nos tornam diferenciados</p>
          <span class="orange_bar_decoration"></span>
        </div>
        <ul class="list-unstyled mx-5 px-5 text-dark-gray">
          <li class="media">
            <i class="icon-sede h1"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">SEDE</p>
              <p class="txt3">Moderna e confiável, ideal para receber nossos clientes. A Next traz comodidade para
                você.</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-hands h1"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">PROFISSIONAIS</p>
              <p class="txt3">Consultores de venda e locação, especialistas em suas respectivas áreas de atuação.</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-target h1 ml-2 mr-3"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">MARKETING</p>
              <p class="txt3">Apresentação dos imóveis com qualidade diferenciada.</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-building h1 ml-2 mr-3"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">PRODUTOS</p>
              <p class="txt3">Os melhores imóveis de Campinas e região. Ampla carteira de imóveis, com grande
                variedade de produtos.</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-card-company h1 mr-2"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">DIVULGAÇÃO</p>
              <p class="txt3">Forte presença na web, explorando as mais modernas e eficientes ferramentas,
                proporcionando maior visibilidade para os imóveis</p>
            </div>
          </li>
          <li class="media mt-3">
            <i class="icon-juridical h1 ml-2 mr-3"></i>
            <div class="media-body mx-4">
              <p class="mt-0 mb-1 txt3-subtitle">JURÍDICO</p>
              <p class="txt3">Departamento interno que acompanha todo o processo do negócio imobiliário.</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block bg-gray-100 pt-5">
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-10 offset-1">
          <ul class="list-unstyled ul-nopadding">
            <li class="media" *ngFor="let historia of historias">
              <p class="text-dark-gray mr-4 tl-title">{{historia.data}}</p>
              <img src="assets/img/icon-timeline.png" alt="EscritórioNext Soluções Imobiliárias"
                   class="img-fluid ml-1">
              <div class="media-body mx-4">
                <p class="text-gray-300 tl-text">{{historia.texto}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-gray d-md-block d-lg-block d-xl-block sobre-especialistas">
    <div class="container">
      <div class="ptu-5">
        <div class="esp-text text-center">
          <p class="top-text">Somos especialistas em<br>médio e alto padrão</p>
        </div>
      </div>
      <div class="row pt-md-5 mt-md-5">
        <div class="offset-md-1 offset-lg-1 col-md-2 col-lg-2 text-center">
          <img src="assets/img/icons/icon-money.png" alt="" class="img-fluid img-icon">
          <p class="text-dark-gray font-weight-light mt-3 esp-text">Venda</p>
        </div>
        <div class="col-md-3 col-lg-3 text-center">
          <img src="assets/img/icons/icon-key.png" alt="" class="img-fluid img-icon">
          <p class="text-dark-gray font-weight-light mt-3 esp-text">Locação</p>
        </div>
        <div class=" col-md-3 col-lg-3 text-center">
          <img src="assets/img/icons/icon-paper.png" alt="" class="img-fluid img-icon">
          <p class="text-dark-gray font-weight-light mt-3 esp-text">Administração</p>
        </div>
        <div class="col-md-2 col-lg-2 text-center">
          <img src="assets/img/icons/icon-star.png" alt="" class="img-fluid img-icon">
          <p class="text-dark-gray font-weight-light mt-3 esp-text">Lançamentos</p>
        </div>
      </div>
      <div class="col-md-12 pbu-5"></div>
    </div>
    <div class="row bg-white py-5 d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <div class="col-12 custom-title ">
        <h2 class="top-text">Visite-nos!</h2>
        <span class="orange_bar_decoration"></span>
        <h3 class="mt-4">Visite o nosso escritório e descubra o universo de<br>soluções que a Next pode oferecer para
          você.</h3>
      </div>
    </div>


    <div class="offset-2 offset-sm-2 col-8 col-sm-8 py-5 text-center d-block d-sm-block d-md-none">
      <p class="title-regular mb-4 text-center text-gray top-text">Visite-nos</p>
      <span class="orange_bar_decoration"></span>
      <p class="text-hist pt-3">Visite o nosso escritório e descubra o universo de soluções que a Next pode oferecer
        para você.</p>
    </div>


    <div class="row">
      <div class="col-12 px-0 d-flex justify-content-center">

        <!--          <button class="btn btn-white text-uppercase px-3" id="btn-trace-route"><img class="pr-1"-->
        <!--                                                                                      src="assets/img/icons/icon-navegation.svg"-->
        <!--                                                                                      width="20"/> Traçar Rota-->
        <!--          </button>-->

        <div class="col-6 card shadow-sm label-map d-none d-sm-none d-md-block d-lg-block d-xl-block">
          <div class="card-body text-center">
            <i class="icon-pin mr-3"></i> Av. Dr. Jesuíno Marcondes Machado, 440 - Nova Campinas, Campinas - SP
          </div>

        </div>


        <div class="col-8 card shadow-sm label-map d-block d-sm-block d-md-none">
          <div class="row card-body text-center">

            <div class="col-2 col-sm-2 text-right">
              <i class="icon-pin ml-3 mt-4"></i>
            </div>

            <div class="col-10 col-sm-10">
              Av. Dr. Jesuíno Marcondes Machado, 440 - Nova Campinas, Campinas - SP
            </div>

          </div>
        </div>

        <section id="cd-google-map" *ngIf="apiLoaded | async">

          <google-map class="w-100" [center]="{lat: latitude, lng: longitude}"
                      [zoom]="16">
          </google-map>

        </section>
      </div>
    </div>

  </section>
</div>


