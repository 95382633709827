<form>
  <div class="row">
    <div class="col">
      <p>Preencha e envie o formulário abaixo para se cadastrar.</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
        <label class="form-check-label" for="inlineRadio1">Locatário</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
        <label class="form-check-label" for="inlineRadio2">Fiador</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label>Nome</label>
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label>E-mail</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Telefone/celular</label>
      <input type="text" class="form-control">
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label>Data de nascimento</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Nacionalidade</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>RG</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>CPF</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>Estado civíl</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Profissão</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>Local de trabalho</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Função</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>Salário</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Endereço do trabalho</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>Cartório para reconhecimento de firma</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Grau de parentesco com o fiador</label>
      <input type="text" class="form-control">
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h4>DADOS DO CONJUGUE</h4>
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>Nome</label>
      <input type="text" class="form-control">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label>E-mail</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Telefone/celular</label>
      <input type="text" class="form-control">
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label>Data de nascimento</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Nacionalidade</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>RG</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>CPF</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>Estado civíl</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Profissão</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>Local de trabalho</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Função</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <label>Salário</label>
      <input type="text" class="form-control">
    </div>

    <div class="col">
      <label>Endereço do trabalho</label>
      <input type="text" class="form-control">
    </div>
  </div>


  <div class="row">
    <div class="col">
      <h4>INFORMAÇÕES BANCÁRIAS</h4>
    </div>
  </div>



  <div class="row">
    <div class="col">
      <label>Banco</label>
      <input type="text" class="form-control">
    </div>
    <div class="col">
      <label>Conta</label>
      <input type="text" class="form-control">
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label>Contato bancário</label>
      <input type="text" class="form-control">
    </div>
  </div>




  <div class="row">
    <div class="col">
      <h4>REFERÊNCIA COMERCIAL</h4>
    </div>
  </div>


  <div class="row">
    <div class="col">
      <h4>DOCUMENTAÇÃO</h4>
      <p>Anexe abaixo cópias da documentação necessária para o andamento do cadastro.</p>
    </div>
  </div>


</form>
