import {Injectable} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Imovel} from '../../imoveis/models/imovel.model';
import {finalize, first, map, tap, catchError, switchMap} from 'rxjs/operators';
import {PATH_IMOVEIS} from '../utils/constants.util';
import {collection, Firestore, getDocs, orderBy, query, where, limit, startAfter} from '@angular/fire/firestore';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {toAreaInt} from '../utils/imovel.util';
import { IQuery } from '../components/models/IFormData';
import {allNgComponent} from 'codelyzer/walkerFactory/walkerFactory';
import IAutoComplete from '../types/IAutoComplete';
import { DocumentData } from 'rxfire/firestore/interfaces';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AllImoveis {
  private autocompleteSubject = new BehaviorSubject<IAutoComplete[]>([]);
  private imoveisSubject = new BehaviorSubject<Imovel[]>([]);

  public autocomplete$ = this.autocompleteSubject.asObservable();
  public imoveis$ = this.imoveisSubject.asObservable();

  constructor(private ngxService: NgxUiLoaderService, private firestore: Firestore) {

  }

  // mountAutoComplete(){
  //   let autocomplete: IAutoComplete[] = [];
  //   return from(getDocs(query(collection(this.firestore, PATH_IMOVEIS))))
  //     .pipe(
  //       map(
  //         actions => actions.docs.map(a => a.data())
  //       ),
  //       tap((value: Imovel[]) => value.forEach(val => {
  //         // buscando valor de bairro
  //         if (val.local.bairro && val.local.bairro !== '' && val.local.bairro !== undefined){
  //           autocomplete.push({
  //             finalidade: val.finalidade,
  //             locacao: val.comercializacao?.locacao?.ativa ? val.comercializacao?.locacao?.ativa : false,
  //             venda: val.comercializacao?.venda?.ativa ? val.comercializacao?.venda?.ativa : false,
  //             type: 'bairro',
  //             value: val.local.bairro.trim()
  //           });
  //         }
  //         // buscando valor de cidade
  //         if (val.local.cidade && val.local.cidade !== '' && val.local.cidade !== undefined){
  //           autocomplete.push({
  //             finalidade: val.finalidade,
  //             locacao: val.comercializacao?.locacao?.ativa ? val.comercializacao?.locacao?.ativa : false,
  //             venda: val.comercializacao?.venda?.ativa ? val.comercializacao?.venda?.ativa : false,
  //             type: 'cidade',
  //             value: val.local.cidade.trim().split('/')[0]
  //           });
  //         }
  //         // buscando valor de condominio
  //         if (val.local.condominio && val.local.condominio !== '' && val.local.condominio !== undefined){
  //           autocomplete.push({
  //             finalidade: val.finalidade,
  //             locacao: val.comercializacao?.locacao?.ativa ? val.comercializacao?.locacao?.ativa : false,
  //             venda: val.comercializacao?.venda?.ativa ? val.comercializacao?.venda?.ativa : false,
  //             type: 'condominio',
  //             value: val.local.condominio.trim()
  //           });
  //         }
  //         // buscando valor de rua
  //         if (val.local.rua && val.local.rua !== '' && val.local.rua !== undefined){
  //           autocomplete.push({
  //             finalidade: val.finalidade,
  //             locacao: val.comercializacao?.locacao?.ativa ? val.comercializacao?.locacao?.ativa : false,
  //             venda: val.comercializacao?.venda?.ativa ? val.comercializacao?.venda?.ativa : false,
  //             type: 'rua',
  //             value: val.local.rua.trim()
  //           });
  //         }
  //         // console.log(autocomplete);
  //         autocomplete = autocomplete.filter((item) => (
  //           item.venda === true || item.locacao === true
  //         ));
  //         this.autocompleteSubject.next(autocomplete);
  //       })
  //     ));
  // }

  getImoveisByBairro(bairro: string) {
    return from(getDocs(query(collection(this.firestore, PATH_IMOVEIS), where('local.bairro', '==', bairro))))
      .pipe(
        map(actions => actions.docs.map(a => {
          return a.data();
        })),
      );
  }

  getImoveisByCidade(cidade: string) {
    return from(getDocs(query(collection(this.firestore, PATH_IMOVEIS), where('local.cidade', '==', cidade))))
      .pipe(
        map(actions => actions.docs.map(a => {
          return a.data();
        })),
      );
  }

  getImoveisByFinalidadeTipo(finalidade: string, categoria: string) {
    return from(getDocs(query(collection(this.firestore, PATH_IMOVEIS),
      where('finalidade', '==', finalidade),
      where(categoria === 'comprar' ? 'comercializacao.venda.ativa' : 'comercializacao.locacao.ativa', '==', true)
      )))
      .pipe(
        map(actions => actions.docs.map(a => {
          return a.data();
        })),
      );
  }

  getImoveisDestaque() {
    return from(getDocs(query(collection(this.firestore, PATH_IMOVEIS),
      where('site.imobiliaria.destaque', '==', true),
    )))
      .pipe(
        map(actions => actions.docs.map(a => {
          return a.data();
        })),
      );
  }

  getImoveis(customSearch: any, last?: Imovel) {
    const wheres = [];

    const compra = 'comercializacao.venda.ativa';
    const venda = 'comercializacao.locacao.ativa';

    let isIn = false;
    let needSubfilter = false;
    if (customSearch.finalidade) {
      wheres.push(where('finalidade', '==', customSearch.finalidade));
    }

    if (customSearch.categoria) {
      wheres.push(where(customSearch.categoria === 'comprar' ? compra : venda, '==', true));
    }

    if (customSearch.cidade) {
      wheres.push(where('local.cidade', '==', customSearch.cidade));
    }

    //
    if (customSearch.bairros?.length > 0) {
      if (customSearch.bairros?.length > 10) {
        return;
      }
      if (customSearch.bairros?.length > 1) {
        wheres.push(where('local.bairro', 'in', customSearch.bairros));
        isIn = true;
      } else {
        wheres.push(where('local.bairro', '==', customSearch.bairros[0]));
      }
    }

    if (customSearch.tipos?.length > 0) {
      if (customSearch.tipos?.length > 1 && !isIn) {
        if (customSearch.tipos?.length > 10) {
          return;
        }
        wheres.push(where('tipo', 'in', customSearch.tipos));
      } else if (customSearch.tipos?.length > 1) {
        needSubfilter = true;
      } else {
        wheres.push(where('tipo', '==', customSearch.tipos[0]));
      }
    }


    wheres.push(orderBy('sigla'));


    return from(getDocs(query(collection(this.firestore, PATH_IMOVEIS), ...wheres)))
      .pipe(
        map(actions => actions.docs.map(a => {
          return a.data();
        })),
        map(value => value.filter(value => {
          return needSubfilter ? customSearch.tipos.includes(value.tipo) : true;
        })),
        map(value => value.filter((value: Imovel) => {
          let is = true;
          if (parseInt(customSearch.banheiros) > 0 && value.numeros?.banheiros) {
            is = parseInt(customSearch.banheiros) === 4 ? value.numeros.banheiros >= 4 : value.numeros.banheiros === parseInt(customSearch.banheiros);
          }

          if (is && parseInt(customSearch.dormitorios) > 0 && value.numeros?.dormitorios) {
            is = parseInt(customSearch.dormitorios) === 4 ? value.numeros.dormitorios >= 4 : value.numeros.dormitorios === parseInt(customSearch.dormitorios);
          }

          if (is && parseInt(customSearch.garagem) > 0 && value.numeros?.vagas) {
            is = parseInt(customSearch.garagem) === 4 ? value.numeros.vagas >= 4 : value.numeros.vagas === parseInt(customSearch.garagem);
          }
          if (is && parseInt(customSearch.salas) > 0 && value.numeros?.salas) {
            is = parseInt(customSearch.salas) === 4 ? value.numeros.salas >= 4 : value.numeros.salas === parseInt(customSearch.salas);
          }

          if (is && parseInt(customSearch.area?.min) > 0 && toAreaInt(value)) {
            is = parseInt(customSearch.area?.min) <= toAreaInt(value);
          }
          if (is && parseInt(customSearch.area?.max) > 0 && toAreaInt(value)) {
            is = parseInt(customSearch.area?.max) >= toAreaInt(value);
          }


          if (is && parseInt(customSearch.precos?.min) && (customSearch.categoria === 'comprar' ? value.comercializacao?.venda?.preco : value.comercializacao?.locacao?.preco)) {
            is = parseInt(customSearch.precos?.min) <= (customSearch.categoria === 'comprar' ? value.comercializacao?.venda?.preco : value.comercializacao?.locacao?.preco);
          }
          if (is && parseInt(customSearch.precos?.max) && (customSearch.categoria === 'comprar' ? value.comercializacao?.venda?.preco : value.comercializacao?.locacao?.preco)) {
            is = parseInt(customSearch.precos?.max) >= (customSearch.categoria === 'comprar' ? value.comercializacao?.venda?.preco : value.comercializacao?.locacao?.preco);
          }

          return is;
        }))
      );
  }

  createDropDownAutoComplete(imoveis:Imovel[]):void {
    let autocomplete:IAutoComplete[] = [];
    imoveis.forEach(val => {
      // buscando valor de bairro
      if (val.local.bairro && val.local.bairro !== '' && val.local.bairro !== undefined){
        autocomplete.push({
          finalidade: val.finalidade,
          locacao: val.comercializacao?.locacao?.ativa ? val.comercializacao?.locacao?.ativa : false,
          venda: val.comercializacao?.venda?.ativa ? val.comercializacao?.venda?.ativa : false,
          type: 'bairro',
          value: val.local.bairro.trim()
        });
      }
      // buscando valor de cidade
      if (val.local.cidade && val.local.cidade !== '' && val.local.cidade !== undefined){
        autocomplete.push({
          finalidade: val.finalidade,
          locacao: val.comercializacao?.locacao?.ativa ? val.comercializacao?.locacao?.ativa : false,
          venda: val.comercializacao?.venda?.ativa ? val.comercializacao?.venda?.ativa : false,
          type: 'cidade',
          value: val.local.cidade.trim().split('/')[0]
        });
      }
      // buscando valor de condominio
      if (val.local.condominio && val.local.condominio !== '' && val.local.condominio !== undefined){
        autocomplete.push({
          finalidade: val.finalidade,
          locacao: val.comercializacao?.locacao?.ativa ? val.comercializacao?.locacao?.ativa : false,
          venda: val.comercializacao?.venda?.ativa ? val.comercializacao?.venda?.ativa : false,
          type: 'condominio',
          value: val.local.condominio.trim()
        });
      }
      // buscando valor de rua
      if (val.local.rua && val.local.rua !== '' && val.local.rua !== undefined){
        autocomplete.push({
          finalidade: val.finalidade,
          locacao: val.comercializacao?.locacao?.ativa ? val.comercializacao?.locacao?.ativa : false,
          venda: val.comercializacao?.venda?.ativa ? val.comercializacao?.venda?.ativa : false,
          type: 'rua',
          value: val.local.rua.trim()
        });
      }
      // console.log(autocomplete);
      autocomplete = autocomplete.filter((item) => (
        item.venda === true || item.locacao === true
      ));
      this.autocompleteSubject.next(autocomplete);
    })
  }

  getBySigla(sigla: string) {
    this.ngxService.start('getBySigla');
    return from(getDocs(query(collection(this.firestore, PATH_IMOVEIS), where('sigla', '==', sigla))))
      .pipe(
        map(actions => actions.docs.map(a => {
          return a.data();
        })),
        first(),
        finalize(() => this.ngxService.stop('getBySigla')));
  }


  // getImovelSimilarForType(type: string) {
  //   this.ngxService.start('getRandomByTipo');
  //   return from(getDocs(query(collection(this.firestore, PATH_IMOVEIS), where('tipo', '==', type))))
  //     .pipe(map(actions => {
  //       const allData = actions.docs.map(doc => doc.data());
  //       return allData;
  //     }),
  //     first(),
  //     finalize(() => this.ngxService.stop('getRandomByTipo'))
  //   );
  // }

  getAllImoveisForFilter():Observable<Imovel[]>{
    this.ngxService.start('getImoveisForFilter');
    return from(getDocs(collection(this.firestore, PATH_IMOVEIS)))
    .pipe(map(snapshot => {
      let allData:Imovel[] = snapshot.docs.map((doc) => {
        const docData:DocumentData = doc.data()
        let data:Imovel = docData as Imovel;
        data = {
          ...data,
          comercializacao: {
            ...data?.comercializacao,
            locacao: {
              ...data?.comercializacao?.locacao,
              ativa: data?.comercializacao?.locacao?.ativa ?? false,
            },
            venda:{
              ...data?.comercializacao?.venda,
              ativa: data?.comercializacao?.venda?.ativa ?? false,
            }
          }
        }

        return data;
      })

      return allData
    }),
    tap((im:Imovel[]) => {
      console.log(im.length)
      this.createDropDownAutoComplete(im);
      this.imoveisSubject.next(im);
    }),
    finalize(() => this.ngxService.stop('getImoveisForFilter'))
    )
  }



  getImoveisFilter(obj: IQuery){
    this.ngxService.start('getImoveisFilter');
    const filter = [];

    const finalidade: string = obj?.finalidade || null;
    const categoria: string = obj?.categoria || null;
    const order: string = obj?.order ? obj.order : 'default';

    if(finalidade){
      filter.push(where('finalidade', '==', finalidade));
    }

    if(categoria){
      if (categoria === 'comprar'){
        filter.push(where('comercializacao.venda.ativa', '==', true));
      } else if(categoria === 'alugar'){
        filter.push(where('comercializacao.locacao.ativa', '==', true));
      }
    }

    if (obj?.tipos && obj?.tipos.length > 0){
      const types: string[] = obj.tipos;
      filter.push(where('tipo', 'in', types));
    }

    const collectionRef = collection(this.firestore, PATH_IMOVEIS);
    const queryRef = filter.length > 0 ? query(collectionRef, ...filter) : collectionRef;

    return from(getDocs(queryRef))
      .pipe(map(actions => {
        let allData: Imovel[] = actions.docs.map(doc => {
          const data = doc.data();
          if (!data || typeof data !== 'object') {
            console.warn("Documento inválido encontrado:", doc.id, data);
            return null; // Retorna `null` em vez de `undefined`
          }
          return data;
        }) as Imovel[];

        if (order !== 'default'){
          let key = 'venda';
          if (categoria !== 'comprar'){
            key = 'locacao';
          }
          allData = allData.sort((a, b) => a.comercializacao[key].preco - b.comercializacao[key].preco);
        }

        allData = this.verifyParams(allData, obj, { finalidade, categoria });
        // console.log(allData);
        return allData;
      }),
      first(),
      catchError(error => {
        alert(error);
        console.error('Erro ao buscar imóveis:', error);
        //this.handleError(error); // Função personalizada para lidar com erros
        return [] // Retorna um array vazio como fallback
      }),
      finalize(() => this.ngxService.stop('getImoveisFilter'))
    );
  }
  private dataFilter(data: Array<Imovel>, condicao: (imovel: Imovel) => boolean): Imovel[] {
    return data.filter(condicao);
  }

  private verifyParams(imoveis: Array<Imovel>, params: any, { finalidade, categoria }: {finalidade: string, categoria: string}): Array<Imovel> {
    let filteredImoveis: Array<Imovel> = imoveis;

    if (params?.query && params?.query.trim() !== ''){
      const queryLower = params.query.toLowerCase();
      filteredImoveis = this.dataFilter(filteredImoveis, (imovel: Imovel) => (
        (imovel.local.bairro !== undefined && imovel.local.bairro.toLowerCase().includes(queryLower)) ||
        (imovel.local.cidade !== undefined && imovel.local.cidade.toLowerCase().includes(queryLower)) ||
        (imovel.local.rua !== undefined && imovel.local.rua.toLowerCase().includes(queryLower)) ||
        (imovel.local.condominio !== undefined && imovel.local.condominio.toLowerCase().includes(queryLower)) ||
        (imovel.sigla !== undefined && imovel.sigla.toLowerCase() === queryLower)
      ));
    }

    // filtrar preço
    if (params?.preco_min && params?.preco_min !== null){
      const preco: number = Number(params?.preco_min);
      if (categoria === 'alugar'){
        filteredImoveis = this.dataFilter(filteredImoveis, (imovel: Imovel) => imovel.comercializacao.locacao.preco >= preco);
      } else {
        filteredImoveis = this.dataFilter(filteredImoveis, (imovel: Imovel) => imovel.comercializacao.venda.preco >= preco);
      }
    }
    if (params?.preco_max && params?.preco_max !== null){
      const preco: number = Number(params?.preco_max);
      if (categoria === 'alugar'){
        filteredImoveis = this.dataFilter(filteredImoveis, (imovel: Imovel) => imovel.comercializacao.locacao.preco <= preco);
      } else {
        filteredImoveis = this.dataFilter(filteredImoveis, (imovel: Imovel) => imovel.comercializacao.venda.preco <= preco);
      }
    }

    // filtrar por area
    if (params?.area_min && params?.area_min !== null){
      const area: number = Number(params?.area_min);
      filteredImoveis = this.dataFilter(filteredImoveis, (imovel: Imovel) => (
        (imovel.tipo == 'casa' && imovel.numeros.areas.total >= area) ||
        ((imovel.tipo === 'apartamento' || imovel.tipo === 'sala' || imovel.tipo === 'cobertura') && imovel.numeros.areas.util >= area) ||
        ((imovel.tipo === 'terreno' || imovel.tipo === 'chácara') && imovel.numeros.areas.terreno >= area) ||
        ((imovel.tipo === 'galpão' || imovel.tipo === 'prédio') && imovel.numeros.areas.construida >= area)
      ));
    }

    if (params?.area_max && params?.area_max !== null){
      const area: number = Number(params?.area_max);
      filteredImoveis = this.dataFilter(filteredImoveis, (imovel: Imovel) => (
        (imovel.tipo == 'casa' && imovel.numeros.areas.total <= area) ||
        ((imovel.tipo === 'apartamento' || imovel.tipo === 'sala' || imovel.tipo === 'cobertura') && imovel.numeros.areas.util <= area) ||
        ((imovel.tipo === 'terreno' || imovel.tipo === 'chácara') && imovel.numeros.areas.terreno <= area) ||
        ((imovel.tipo === 'galpão' || imovel.tipo === 'prédio') && imovel.numeros.areas.construida <= area)
      ));
    }

    // filtra dormitórios
    if (params?.dormitorios && params?.dormitorios !== null){
      const num: number = Number(params?.dormitorios);
      let func: (imovel: Imovel) => boolean = (imovel: Imovel): boolean => imovel.numeros.dormitorios == num;
      if (num == 4){
        func = (imovel: Imovel): boolean => imovel.numeros.dormitorios >= num;
      }
      filteredImoveis = this.dataFilter(filteredImoveis, func);
    }

    // filtra banheiros
    if (params?.banheiros && params?.banheiros !== null){
      const num: number = Number.parseInt(params?.banheiros);
      let func: (imovel: Imovel) => boolean = (imovel: Imovel): boolean => imovel.numeros.banheiros == num;
      if (num == 4){
        func = (imovel: Imovel): boolean => imovel.numeros.banheiros >= num;
      }
      filteredImoveis = this.dataFilter(filteredImoveis, func);
    }

    // filtrar garagens
    if (params?.garagem && params?.garagem !== null){
      const num: number = Number.parseInt(params?.garagem);
      let func: (imovel: Imovel) => boolean = (imovel: Imovel): boolean => imovel.numeros.vagas == num;
      if (num == 4){
        func = (imovel: Imovel): boolean => imovel.numeros.vagas >= num;
      }
      filteredImoveis = this.dataFilter(filteredImoveis, func);
    }


    return filteredImoveis;
  }

}
