export const TIPOS_COMERCIAL = [
  "área",
  "casa",
  "galpão",
  "sala",
  "salão",
  "prédio",
  "terreno"
];

export const TIPOS_RESIDENCIAL = [
  "apartamento",
  "casa",
  "chácara",
  "cobertura",
  "flat",
  "terreno"
]
