import {AfterViewInit, Component, OnDestroy, Renderer2, OnInit} from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { AllImoveis } from './core/services/all-imoveis.service';
import { IOSDetectionService } from './core/services/iosdetection.service';
import { AutocompleteDropdownService } from './core/services/autocomplete-dropdown.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnDestroy{
  title = 'nextsim';

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  private subMountAutoComplete!: Subscription;
  private subImoveis!:Subscription;

  constructor(
    private ccService: NgcCookieConsentService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private allImoveis: AllImoveis,
    private renderer: Renderer2,
    private iosService: IOSDetectionService,
    private dropown: AutocompleteDropdownService
  ){}

  ngOnInit() {
    // this.subMountAutoComplete = this.allImoveis.mountAutoComplete().subscribe({
    //   next: () => {
    //     this.dropown.activeAutoComplete();
    //     console.log('Autocomplete carregado com sucesso')
    //   },
    //   error: (error) => console.error('Erro ao carregar autocomplete', error)
    // });

    this.subImoveis = this.allImoveis.getAllImoveisForFilter().pipe(take(1)).subscribe({
      next(){
        console.log("Imóveis carregados!");
      },
      error: (error) => console.error('Erro ao carregar autocomplete', error)
    });
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd)) // Filtra eventos NavigationEnd
      .subscribe((event:NavigationEnd) => {
        this.viewportScroller.scrollToPosition([0, 0]);


        // if (this.iosService.isIOS()) {
        //   this.renderer.listen('document', 'click', (event: MouseEvent) => {
        //     const target = (event.target as HTMLElement).closest('a');
        //     if (target && target.getAttribute('href')) {
        //       event.preventDefault();
        //       const href = target.getAttribute('href');
        //       if (href) {
        //         window.location.href = href; // Força o carregamento da página
        //       }
        //     }
        //   });
        // }
      });
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializingSubscription = this.ccService.initializing$.subscribe(
      (event) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      });

    this.initializedSubscription = this.ccService.initialized$.subscribe(
      (event) => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        console.log(`initialized: ${JSON.stringify(event)}`);
      });

    this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
      (event) => {
        // the cookieconsent has failed to initialize...
        console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
    this.subMountAutoComplete.unsubscribe();
  }
}
